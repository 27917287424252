import styled from "styled-components";

export const ContainerForm = styled.form`
  > div:nth-child(1) {
    display: flex;
    gap: 16px;

    > div {
      display: flex;
      flex-direction: column;
      gap: 16px;

      width: 100%;
    }
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  margin-bottom: 47px;

  padding: 20px 0;

  > a {
    align-items: center;
    display: flex;
    text-decoration: none;

    gap: 2.5vw;
  }

  > h1 {
    color: #fff;
    font-size: 2rem;
    font-weight: 400;
  }

  > a {
    width: max-content;
  }

  > div {
    width: 50%;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: start;
  flex-direction: row !important;

  gap: 20px;

  height: auto;

  margin-top: 20px;

  > button,
  a {
    background: linear-gradient(271.14deg, #7229f5 1.5%, #0a3fd6 98.6%);
    align-items: center;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    max-width: 245px;
    padding: 1vw;

    color: #fff;

    gap: 10px;

    font-size: 18px;
  }

  > a {
    text-decoration: none;
    background: rgba(255, 255, 255, 0.1);

    border: 1.5px solid rgba(255, 255, 255, 1);

    max-width: max-content;
  }
`;

export const ContainerDate = styled.div`
  display: flex;

  gap: 16px;

  margin-top: 32px;

  > div {
    width: 100%;
  }
  > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    gap: 16px;

    > h1 {
      font-size: 18px;
      color: rgba(255, 255, 255, 1);
      font-weight: 600;
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    gap: 16px;

    > h1 {
      font-size: 18px;
      color: rgba(255, 255, 255, 1);
      font-weight: 600;
    }
  }
`;

export const ContainerFreeDrink = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0 !important;
`;

export const ContainerError = styled.div`
  display: flex;
  align-items: center;

  > h1 {
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
    font-weight: 600;

    padding: 0px 16px;

    margin-bottom: 0.5vw;
  }
`;

export const ErrorMessage = styled.p`
  color: #ff0000;
  font-size: 0.8em;
`;

export const MessageInfo = styled.span`
  color: #ffffff80;

  font-size: 14px;

  font-weight: 400;

  line-height: 16.8px;

  margin-top: 16px;
`;

export const ContainerMessageInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerStartDate = styled.div`
  display: flex;

  > div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 16px;

    > div {
      width: 100%;
    }
  }
`;
