import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import {
  ButtonActions,
  ButtonExportData,
  ContainerActions,
  ContainerCustomButton,
  ContainerSorts,
  ContainerTable,
  ContentHeader,
  Status,
  InputSearch,
  CustomerButtons,
  ContainerModifyHeader
} from "./styles";
import * as XLSX from 'xlsx';

const TableColumn = ({
  title,
  columns,
  data,
  actions,
  sortableColumns,
  hasExport,
  hasSearch,
  customButton,
  totalPages,
  currentPage: initialPage = 1,
  onPageChange,
  records = 10,
  hasHeader = true,
  hasModifyHeader = false,
  inputSearchProps,
  fetchAllData,
  sortBy,
  sortDirection,
  onSort,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isExporting, setIsExporting] = useState(false);
  const [currentPage, setCurrentPage] = useState(initialPage);

  useEffect(() => {
    setSearchTerm(inputSearchProps?.value || "");
  }, [inputSearchProps?.value]);

  const exportData = async () => {
    setIsExporting(true);
    try {
      const allData = await fetchAllData();

      if (allData.length === 0) {
        console.error("No data available for export.");
        setIsExporting(false);
        return;
      }

      const worksheet = XLSX.utils.json_to_sheet(allData);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, title);

      XLSX.writeFile(workbook, `${title}_data.xlsx`);
    } catch (error) {
      console.error(`Error exporting data: ${error}`);
    } finally {
      setIsExporting(false);
    }
  };

  const handleSort = (columnName) => {
    onSort(columnName);
  };

  const handlePageClick = ({ selected }) => {
    const newPage = selected + 1;
    if (currentPage !== newPage) {
      setCurrentPage(newPage);
      onPageChange?.(newPage);
    }
  };

  const sortedData = () => {
    if (sortBy) {
      return data.slice().sort((a, b) => {
        const aValue = a[sortBy];
        const bValue = b[sortBy];
        if (typeof aValue === "string" && typeof bValue === "string") {
          return sortDirection === "asc"
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        } else if (sortBy === "date") {
          return sortDirection === "asc" ? aValue - bValue : bValue - aValue;
        } else {
          return sortDirection === "asc"
            ? aValue.toString().localeCompare(bValue.toString())
            : bValue.toString().localeCompare(aValue.toString());
        }
      });
    }
    return Array.isArray(data) ? data : [];
  };

  const filteredData = sortedData().filter((row) =>
    Object.values(row).some((value) =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "long" });
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  };

  return (
    <ContainerTable>
      {!hasModifyHeader && hasHeader && (
        <ContentHeader>
          {title && <h1>{title}</h1>}

          <CustomerButtons>
            {hasSearch && (
              <InputSearch
                className="input-search"
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                {...inputSearchProps}
              />
            )}
            {customButton && (
              <ContainerCustomButton>{customButton}</ContainerCustomButton>
            )}
            {hasExport && (
              <ButtonExportData onClick={exportData} disabled={isExporting}>
                {isExporting ? "Exporting..." : "Export Data"}
              </ButtonExportData>
            )}
          </CustomerButtons>
        </ContentHeader>
      )}

      {hasModifyHeader && (
        <ContainerModifyHeader>
          <ContainerSorts>
            {/* {sortableColumns.map((column, index) => (
              <button key={index} onClick={() => handleSort(column)}>
                Sort by {column}
              </button>
            ))} */}
          </ContainerSorts>

          <div>
            <InputSearch
              className="input-search"
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              {...inputSearchProps}
            />

            <ButtonExportData onClick={exportData} disabled={isExporting}>
              {isExporting ? "Exporting..." : "Export Data"}
            </ButtonExportData>
          </div>
        </ContainerModifyHeader>
      )}

      {!hasModifyHeader && (
        <ContainerSorts>
          {/* {sortableColumns.map((column, index) => (
            <button key={index} onClick={() => handleSort(column)}>
              Sort by {column}
            </button>
          ))} */}
        </ContainerSorts>
      )}

      <div className="table-wrapper">
        <table>
          <thead>
          <tr>
            {columns.map((column, index) => (
                <th key={index}>{column}</th>
            ))}
            {actions && <th></th>}
          </tr>
          </thead>
          <tbody>
          {filteredData.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column, colIndex) => (
                    <Status key={colIndex} status={String(rowData[column])}>
                      {column === "Date" ? formatDate(rowData[column]) : rowData[column]}
                    </Status>
                ))}
                {actions && (
                    <ContainerActions>
                      {actions.map((action, actionIndex) => (
                          <ButtonActions
                              key={actionIndex}
                              onClick={() => action.onClick(rowData)}
                          >
                            {action.label}
                          </ButtonActions>
                      ))}
                    </ContainerActions>
                )}
              </tr>
          ))}
          </tbody>

        </table>

      </div>

      {typeof totalPages === "number" && totalPages > 0 && (
          <ReactPaginate
              pageCount={Math.ceil(totalPages / records)}
              onPageChange={handlePageClick}
              previousLabel={null}
              nextLabel={
                currentPage !== Math.ceil(totalPages / records) ? "Next" : null
              }
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              containerClassName={"pagination"}
              pageLinkClassName={"page-number"}
              previousLinkClassName={"page-number-prev"}
              nextLinkClassName={"page-number-next"}
              activeClassName={"active-number"}
              forcePage={currentPage - 1}
        />
      )}
    </ContainerTable>
  );
};

export default TableColumn;

