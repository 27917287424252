import React from "react";
import styled from "styled-components";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const StyledDatePicker = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 6px;

  label {
    display: flex;
    align-items: center;
    padding: 0px 16px;
    gap: 10px;

    color: rgb(255, 255, 255);
    font-weight: bold;

    font-size: 14px;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    width: 100%;

    > input {
      background-color: rgba(255, 255, 255, 0.1);
      color: #ffffff;
      outline: none;
      border: none;
      border-radius: 8px;
      padding: 13px 16px;
      min-height: 54px;

      width: 100%;

      font-size: 14px;
    }
  }

  .react-datepicker {
    font-family: "Arial", sans-serif;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
  }

  .react-datepicker__header {
    background-color: #f0f0f0;
    border-bottom: 1px solid #ddd;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .react-datepicker__day-names {
    padding-top: 8px;
  }

  .react-datepicker__day {
    font-size: 14px;
  }

  .react-datepicker__day--selected {
    background-color: #007bff;
    color: white;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #007bff;
    color: white;
  }

  .react-datepicker__month {
    margin: 0.4em 1em;
  }

  .react-datepicker__time-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .react-datepicker__time-list-item {
    padding: 8px 10px;
    cursor: pointer;
  }

  .react-datepicker__time-list-item--selected {
    background-color: #007bff;
    color: white;
  }
`;

const ErrorMessage = styled.p`
  color: #ff0000;
  font-size: 0.8em;
`;

const ContainerLabel = styled.div`
  display: flex;
  align-items: center;
`;

const CustomDatePickerBusiness = ({
  label,
  selectedDate,
  onChange,
  defaultValue,
  error,
  ...restProps
}) => {
  const handleChange = (date) => {
    onChange(date);
  };

  return (
    <StyledDatePicker>
      <ContainerLabel>
        {label && <label>{label}</label>}

        {error && <ErrorMessage>{error}</ErrorMessage>}
      </ContainerLabel>
      <DatePicker
        selected={selectedDate}
        onChange={handleChange}
        dateFormat="MM/dd/yyyy"
        placeholderText="MM/DD/YYYY"
        value={defaultValue}
        {...restProps}
      />
    </StyledDatePicker>
  );
};

export default CustomDatePickerBusiness;
