import React, { useEffect, useState, useCallback } from "react";
import { MdOutlineRemoveRedEye, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import SideBar from "../../components/SideBar/SideBar";
import TopNav from "../../components/TopNav/TopNav";
import TableColumn from "../../components/TableColumn/TableColumn";
import api from "../../services/api";
import "./Users.scss";
import { format } from "date-fns";

const Users = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    Number(localStorage.getItem("ListCurrentPageUsers")) || 1
  );

  const records = 10;
  const [userToDelete, setUserToDelete] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("null");

  const formatDate = (dateString) => {
    return format(new Date(dateString), "MMMM d, yyyy");
  };

  const getUsers = useCallback(
    async (page) => {
      try {
        const response = await api.get(`/users`, {
          params: {
            page: page,
            records: records,
            sort_column: sortBy,
            sort: sortDirection,
          },
        });

        const itemsTableColumns = response.data.data.map((user) => ({
          Name: `${user.firstName} ${user.lastName}`,
          Birthday: user.birthday,
          Gender: user.gender,
          "Member Since": formatDate(user.created_at),
          "Last Active": formatDate(user.last_active),
          Email: user.email,
          Phone: user.phone,
          Instagram: user.ig,
          id: user.id,
        }));

        setUsers(itemsTableColumns);
        setTotalPages(response.data.total);
      } catch (error) {
        console.log(`users get request didn't work. error: ${error}`);
      }
    },
    [sortBy, sortDirection]
  );

  useEffect(() => {
    getUsers(currentPage, sortDirection);
  }, [currentPage, sortDirection, getUsers]);

  const handleDelete = (id) => {
    const deleteUser = async () => {
      try {
        await api.delete(`/users/${id}`);
        setDeleteModal(false);
        getUsers(currentPage, sortDirection);
      } catch (err) {
        console.log(`failed to delete user. this is the error: ${err}`);
      }
    };

    deleteUser();
  };

  const fetchAllData = async () => {
    try {
      const response = await api.get(`/users`, {
        params: {
          records: 9999999999,
        },
      });

      return response.data.data.map((user) => ({
        Name: `${user.firstName} ${user.lastName}`,
        Birthday: user.birthday,
        Gender: user.gender,
        "Member Since": formatDate(user.created_at),
        "Last Active": formatDate(user.last_active),
        Email: user.email,
        Phone: user.phone,
        Instagram: user.ig,
        id: user.id,
      }));
    } catch (error) {
      console.log(`Error fetching all data: ${error}`);
      return [];
    }
  };

  const columns = [
    "Name",
    "Birthday",
    "Gender",
    "Member Since",
    "Last Active",
    "Email",
    "Phone",
    "Instagram",
  ];

  const sortableColumns = ["Name", "Birthday", "Gender"];

  const actions = [
    {
      label: <MdEdit size={30} color="rgba(167, 41, 245, 1)" />,
      onClick: (rowData) => {
        navigate(`/users/edit/${rowData.id}`);
      },
    },
    {
      label: <MdOutlineRemoveRedEye size={30} color="rgba(167, 41, 245, 1)" />,
      onClick: (rowData) => {
        navigate(`/users/${rowData.id}`);
      },
    },
  ];

  const handleChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("ListCurrentPageUsers", page);
  };

  useEffect(() => {
    const storedPage = localStorage.getItem("ListCurrentPageUsers");
    setCurrentPage(Number(storedPage) || 1);
  }, []);

  const sortColumnsMapping = {
    Name: "firstName",
    Birthday: "birthday",
    Gender: "gender",
  };

  const handleSort = (columnName) => {
    const backendColumnName = sortColumnsMapping[columnName];
    if (sortBy === backendColumnName) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(backendColumnName);
      setSortDirection("asc");
    }
  };

  return (
    <main>
      <TopNav />
      <div className="main-container">
        <SideBar />
        {deleteModal && (
          <DeleteModal
            handleDelete={handleDelete}
            setDeleteModal={setDeleteModal}
            modToDelete={userToDelete}
            tableName={"USERS"}
          />
        )}
        <article
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <section className="users">
            <TableColumn
              title="Users"
              hasHeader={true}
              columns={columns}
              data={users}
              sortableColumns={sortableColumns}
              hasExport={true}
              hasSearch={true}
              actions={actions}
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              records={records}
              customButton={
                <button onClick={() => navigate("/users/add")}>
                  Add new user +
                </button>
              }
              inputSearchProps={{ value: searchTerm, onChange: handleChange }}
              fetchAllData={fetchAllData}
              onSort={handleSort}
            />
          </section>
        </article>
      </div>
    </main>
  );
};

export default Users;
