import './Promotion.scss';

import { saveAs } from 'file-saver';
import { useEffect, useState } from 'react';

import search from '../../assets/images/icons/Search.svg';
import upload from '../../assets/images/icons/upload.svg';
import SideBar from '../../components/SideBar/SideBar';
import TopNav from '../../components/TopNav/TopNav';
import api from '../../services/api';

function Promotion() {
    const [promos, setPromos] = useState([]);
    const [displayPromos, setDisplayPromos] = useState([]);

    useEffect(() => {
        const getPromos = async () => {
            try {
                const response = await api.get("/promos");
                setPromos(response.data);
            } catch (err) {
                console.log(`error fetching promos. this is the error: ${err}`);
            }
        };
        getPromos();
    }, []);

    //SEARCH =====================================================
    const [searchQuery, setSearchQuery] = useState("");
    const [activeSearch, setActiveSearch] = useState(false);
    const [filderedPromos, setFilteredPromos] = useState([]);

    function handleChange(event) {
        setActiveSearch(true);
        setSearchQuery(event.target.value);

        const filtered = promos.filter(
            (item) =>
                item.user.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.date.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.venue.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.event.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.subjec.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.body.toLowerCase().includes(event.target.value.toLowerCase())
        );
        setFilteredPromos(filtered);
    }

    useEffect(() => {
        activeSearch ? setDisplayPromos(filderedPromos) : setDisplayPromos(promos);
    }, [activeSearch, filderedPromos, promos]);

    function handleExitSearch() {
        setActiveSearch(false);
        setSearchQuery("");
    }

    //EXPORTING DATA ============================================

    function handleExportDataClick() {
        try {
            const csvRows = [];
            // Extracting headers
            const headers = Object.keys(promos[0]);
            csvRows.push(headers.join(","));

            // Extracting data rows
            for (const row of promos) {
                const values = headers.map((header) => {
                    const escapedValue = row[header].toString().replace(/"/g, '\\"');
                    return `"${escapedValue}"`;
                });
                csvRows.push(values.join(","));
            }

            // Combine rows into CSV content
            const csvContent = csvRows.join("\n");

            // Convert CSV content to blob
            const csvBlob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

            // Trigger download
            saveAs(csvBlob, "promotion_data.csv");
        } catch (error) {
            console.error("Error converting JSON to CSV:", error);
        }
    }

    //SUBNAV TABS ===============================================

    const [relevantPromoActive, setRelevantPromoActive] = useState(true);
    const [completedPromoActive, setCompletedPromoActive] = useState(false);

    function handleSubNavClick(tab) {
        switch (tab) {
            case "relevant":
                setCompletedPromoActive(false);
                setRelevantPromoActive(true);
                break;
            case "completed":
                setRelevantPromoActive(false);
                setCompletedPromoActive(true);
                break;
            default:
                break;
        }
    }

    return (
        <main>
            <TopNav />
            <div className='main-container'>
                <SideBar />
                <article style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <section className='promos'>
                        <div className='promos__top'>
                            <h1 className='promos__top-heading'>
                                Promotion
                            </h1>

                            <div className='promos__top-right'>

                                <div className='promos__top-right-search'>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <label htmlFor='search'>
                                            <img src={search} className='promos__top-right-search-icon' alt='magnifying glass'></img>
                                        </label>
                                        <input
                                            id='search'
                                            type="text"
                                            className='promos__top-right-search-input'
                                            placeholder="Search..."
                                            value={searchQuery}
                                            onChange={handleChange}
                                        />

                                    </div>
                                    {activeSearch &&
                                        <div onClick={handleExitSearch} className='promos__top-right-search-x'>
                                            <p className='promos__top-right-search-x-text'>
                                                X
                                            </p>
                                        </div>
                                    }
                                </div>

                                <button className='promos__top-right-colorful'>
                                    Add new promo +
                                </button>
                                {/* onClick={handleExportDataClick} */}
                                <button className='promos__top-right-clear'>
                                    Export data

                                    <img className='promos__top-right-clear-icon' src={upload} alt='upload icon'></img>
                                </button>
                            </div>
                        </div>

                        <section className='no-data'>
                            <div className='no-data__container'>
                                <p className='no-data__container-text'>
                                    There is no promotion data at this time
                                </p>

                            </div>
                        </section>

                        {/* <div className='promos__sub-nav'>
                            <div
                                onClick={() => handleSubNavClick('relevant')}
                                className={relevantPromoActive ? "promos__sub-nav-link promos__sub-nav-link--active" : "promos__sub-nav-link"}
                            >
                                <p className='promos__sub-nav-link-text'>
                                    Relevant promo
                                </p>
                            </div>
                            <div
                                onClick={() => handleSubNavClick('completed')}
                                className={completedPromoActive ? "promos__sub-nav-link promos__sub-nav-link--active" : "promos__sub-nav-link"}
                            >
                                <p className='promos__sub-nav-link-text'>
                                    Completed promo
                                </p>
                            </div>
                        </div>

                        {relevantPromoActive ? <RelevantPromo promos={promos} /> : <CompletedPromo />} */}
                    </section>

                </article>
            </div>
        </main>
    );
}

export default Promotion;
