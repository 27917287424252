import '../../styles/CalendarStyles.css';
import './EditUsers.scss';

import { format } from 'date-fns';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import checkMark from '../../assets/images/icons/check-mark.svg';
import chevronLeft from '../../assets/images/icons/chevron-left.svg';
import bin from '../../assets/images/icons/delete.svg';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import FormDatePicker from '../../components/FormDatePicker/FormDatePicker';
import InputForm from '../../components/InputForm/InputForm';
import SelectForm from '../../components/SelectForm/SelectForm';
import SideBar from '../../components/SideBar/SideBar';
import TopNav from '../../components/TopNav/TopNav';
import api from '../../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditUsers = () => {
    const { userID } = useParams();
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();
    const [birthday, setBirthday] = useState(new Date());
    const [gender, setGender] = useState();
    const [ig, setIg] = useState();

    const [user, setUser] = useState();

    const [deleteModal, setDeleteModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [newImage, setNewImage] = useState();

    const [errors, setErrors] = useState({});

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required("First name is required"),
        lastName: Yup.string().required("Last name is required"),
        phone: Yup.string().required("Phone number is required"),
        email: Yup.string().email("Invalid email").required("Email is required").matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email format'),
        gender: Yup.string().required("Gender is required"),
        ig: Yup.string().required("Instagram is required"),
    });

    useEffect(() => {
        const getUser = async () => {
            try {
                const response = await api.get(`/users/${userID}`);
                setUser(response.data.user);
            } catch (err) {
                console.log(`error retrieving user. this is the error: ${err}`);
            }
        };
        getUser();
    }, [userID]);

    const formatDate = (date) => {
        if (!date) return "";
        return format(new Date(date), 'yyyy-MM-dd');
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        try {

            await validationSchema.validate({
                firstName,
                lastName,
                phone,
                email,
                gender,
                ig,
            }, { abortEarly: false });

            const editedUser = {
                firstName,
                lastName,
                phone,
                email,
                birthday,
                gender,
                ig,
                admin: isAdmin
            };

            await api.put(`/users/${user.id}`, editedUser);

            setFirstName("");
            setLastName("");
            setPhone("");
            setEmail("");
            setGender("");
            setIg("");

            toast.success('User Edit successfully!');

            navigate("/users");

        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const validationErrors = {};
                error.inner.forEach(err => {
                    validationErrors[err.path] = err.message;
                });
                toast.error(`Error Edit User. Please try again, ${error.message}`);
                setErrors(validationErrors);
            } else {
                console.error("Error edit user:", error);
            }

        };
    }

    const handleDeleteUser = (user) => {
        setDeleteModal(true);
        setUserToDelete(user);
    };

    const handleDelete = async (id) => {
        setIsLoading(true);
        try {
            await api.delete(`/users/${id}`);
            setDeleteModal(false);
            toast.success('User deleted successfully!');

            navigate("/users");
        } catch (err) {
            toast.error(`Error Delete User. Please try again, ${err.message}`);
            console.log(`failed to delete user. this is the error: ${err}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleImageAdd = useCallback(async (events) => {
        if (!user?._id) return;

        const file = events.target.files[0];

        const formData = new FormData();

        formData.append("images", file);

        const { data } = await api.post(`/users/photo/${user._id}`, formData);
        const recentImageAdded = data[0];

        console.log({ data })

        const image = { id: recentImageAdded };

        setNewImage((oldState) => [...oldState, image]);

        alert("Image uploaded successfully!");
    }, [user?._id]);

    const handleImageRemove = useCallback(
        async (_id) => {
            if (!user?._id) return;

            await api.delete(`/users/photo/${user._id}`, {
                params: {
                    images: [_id],
                },
            });

            setNewImage((oldState) => {
                const updatedState = oldState.filter((i) => i._id !== _id);

                return updatedState;
            });

            alert("Image DELETED successfully!");
        },
        [user]
    )

    const handleBirthdaySelect = (selectedBirthday) => {
        setBirthday(selectedBirthday)
    };

    useEffect(() => {
        setFirstName(user?.firstName || '-')
        setLastName(user?.lastName || '-')
        setPhone(user?.phone || '-')
        setEmail(user?.email || '-')
        setBirthday(user?.birthday || '-')
        setGender(user?.gender || '-')
        setIg(user?.ig || '-')
        setIsAdmin(user?.admin)
    }, [user])

    const formatDefaultDate = (date) => {
        if (!date) return null;
        const formattedDate = new Date(date);
        return isNaN(formattedDate.getTime()) ? null : formattedDate.toISOString().split('T')[0];
    };

    const optionsGender = [{ value: "Male", label: "Male" }, { value: "Female", label: "Female" }]

    const handleGenderChange = (selectedOption) => {
        setGender(selectedOption ? selectedOption.value : "");
    }

    const selectedGenderOption = optionsGender.find(option => option.value === gender);

    const [isAdmin, setIsAdmin] = useState(null);

    const handleAdminChange = (selectedOption) => {
        console.log(selectedOption);
        setIsAdmin(selectedOption ? selectedOption.value : "")
    }

    const optionsAdmin = [{ label: 'No', value: false }, { label: 'Yes', value: true }];

    return (
        <main>
            <TopNav />
            <div className="main-container">
                <SideBar />
                {deleteModal && (
                    <DeleteModal
                        handleDelete={handleDelete}
                        setDeleteModal={setDeleteModal}
                        modToDelete={userToDelete}
                        tableName={"USERS"}
                        isLoading={isLoading}
                    />
                )}
                <article style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <section className="edit-user">
                        <div className="edit-user__top">
                            <div className="edit-user__top-left">
                                <Link to="/users">
                                    <img
                                        className="edit-user__top-left-icon"
                                        src={chevronLeft}
                                        alt="Left pointing chevron"
                                    ></img>
                                </Link>
                                <h1 className="edit-user__top-left-heading">Editing User</h1>
                            </div>
                            <div>
                                <button className="edit-user__top-button" onClick={() => handleDeleteUser(user)}>
                                    <p className="edit-user__top-button-text">Delete User</p>
                                    <img src={bin} alt="icon of garbage bin"></img>
                                </button>
                            </div>
                        </div>

                        <form onSubmit={handleFormSubmit} className="edit-user__form">
                            <div className="edit-user__form-first">
                                <SelectForm
                                    label="Admin"
                                    options={optionsAdmin}
                                    value={optionsAdmin.find(option => option.value === isAdmin)}
                                    onChange={handleAdminChange}
                                />

                                <div>
                                    <div>
                                        <InputForm
                                            placeholder="Name"
                                            label="Name"
                                            type="text"
                                            id="name"
                                            name="name"
                                            value={firstName}
                                            onChange={(event) =>
                                                setFirstName(event.target.value)
                                            }
                                            error={errors.firstName}
                                        />
                                    </div>

                                    <div>
                                        <InputForm
                                            placeholder="Last Name"
                                            label="Last Name"
                                            type="text"
                                            id="lastName"
                                            name="lastName"
                                            value={lastName}
                                            onChange={(event) =>
                                                setLastName(event.target.value)
                                            }
                                            error={errors.lastName}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <InputForm
                                            placeholder="Phone"
                                            label="Phone"
                                            type="text"
                                            id="phone"
                                            name="phone"
                                            value={phone}
                                            onChange={(event) =>
                                                setPhone(event.target.value)
                                            }
                                            error={errors.phone}
                                        />
                                    </div>
                                    <div>
                                        <InputForm
                                            placeholder="Email"
                                            label="Email"
                                            type="text"
                                            id="email"
                                            name="email"
                                            value={email}
                                            onChange={(event) =>
                                                setEmail(event.target.value)
                                            }
                                            error={errors.email}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <SelectForm
                                            label="Gender"
                                            options={optionsGender}
                                            value={selectedGenderOption}
                                            onChange={handleGenderChange}
                                            error={errors.gender}
                                        />
                                    </div>

                                    <div>
                                        <FormDatePicker
                                            onBirthdaySelect={handleBirthdaySelect}
                                            defaultDate={formatDefaultDate(birthday)}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <InputForm
                                            placeholder="Instagram"
                                            label="Instagram"
                                            type="text"
                                            id="ig"
                                            name="ig"
                                            value={ig}
                                            onChange={(event) =>
                                                setIg(event.target.value)
                                            }
                                            error={errors.ig}
                                        />
                                    </div>
                                </div>

                                <button type="submit" className="edit-user__form-button">
                                    <p className="edit-user__form-button-text">Update Information</p>
                                    <img src={checkMark} alt="a checkmark"></img>
                                </button>
                            </div>
                        </form>
                    </section>
                </article>
            </div>
        </main>
    );
}

export default EditUsers;
