import React, { useEffect, useState, useCallback } from "react";
import { MdOutlineRemoveRedEye, MdEdit, MdCheck, MdCancel, MdPin, MdMap } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import exit from '../../assets/images/icons/exit.svg';
import SideBar from "../../components/SideBar/SideBar";
import TopNav from "../../components/TopNav/TopNav";
import TableColumn from "../../components/TableColumn/TableColumn";
import api from "../../services/api";
import "./OrganizerRequests.scss";
import { format } from "date-fns";
import { ButtonActions } from "../../components/TableColumn/styles";

const OrganizerRequests = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    Number(localStorage.getItem("ListCurrentPageOrganizerReqs")) || 1
  );

  const [venuesModal, setVenuesModal] = useState(true);
  const [displayVenues, setDisplayVenues] = useState([])

  const records = 10;
  const [searchTerm, setSearchTerm] = useState("");

  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("null");

  const formatDate = (dateString) => {
    return format(new Date(dateString), "MMMM d, yyyy");
  };

  const actions = [
    // {
    //   label: <MdCheck size={30} color="rgba(167, 41, 245, 1)" />,
    //   onClick: async (rowData) => {
    //     if (window.confirm("Are you sure you want to approve this request?")) {
    //       try {
    //         await api.post(`/organizer-requests/review/${rowData._id}`, {
    //           action: 'approve',
    //           reason: ''
    //         });
  
    //         window.location.reload();
    //       } catch (error) {
    //         console.log('Error Processing Request ==> ', error);
    //         alert('Error Processing Request')
    //       }
          
    //     }
    //   },
    // },
    // {
    //   label: <MdCancel size={30} color="red" />,
    //   onClick: async(rowData) => {
    //     let reason = prompt("Are you sure you want to deny this request? \n\n Please provide a reason below");
    //     if (!(reason == null)) {
    //       try {
    //         await api.post(`/organizer-requests/review/${rowData._id}`, {
    //           action: 'disapprove',
    //           reason: (reason || '')
    //         });
  
    //         window.location.reload();

    //       } catch (error) {
    //         console.log('Error Processing Request ==> ', error);
    //         alert('Error Processing Request');
    //       }
    //     }
    //   },
    // },
    {
      label: <MdOutlineRemoveRedEye size={30} color="rgba(167, 41, 245, 1)" />,
      onClick: (requestData) => {
        navigate(`/organizer-requests/${requestData.user_id}`, {
          state: {
            requestData
          }
        });
      },
    },
  ];

  const getUsers = useCallback(
    async (page) => {
      try {
        const response = await api.get(`/organizer-requests`, {
          params: {
            page: page,
            records: records,
            sort_column: sortBy,
            sort: sortDirection,
          },
        });

        const itemsTableColumns = response.data.data.map((user) => ({
          Name: user.name,
          Company: user.company,
          Position: user.title,
          "Date Created": formatDate(user.created_at),
          Email: user.email,
          Phone: user.phone,
          Status: user.review_status,
          Venues: (
            <span
              onClick={() => {
                setDisplayVenues(user?.requested_venues || [])
                setVenuesModal(true)
              }}
                style={{ color: "rgba(167, 41, 245, 1)", cursor: 'pointer', textDecoration: 'underline' }}
            >
                {user?.requested_venues?.length}
            </span>
          ),
          "Last Updated": formatDate(user.updated_at),
          "": (
            <div>
              {actions.map((action, actionIndex) => (
                <ButtonActions
                  key={actionIndex}
                  onClick={() => action.onClick(user)}
                >
                  {action.label}
                </ButtonActions>
              ))}
            </div>
          ),
          id: user._id,
        }));

        setUsers(itemsTableColumns);
        setTotalPages(response.data.total);
      } catch (error) {
        console.error(`Error getting organizer requests. Error: ${error}`);
      }
    },
    [sortBy, sortDirection]
  );

  useEffect(() => {
    getUsers(currentPage, sortDirection);
  }, [currentPage, sortDirection, getUsers]);

  const fetchAllData = async () => {
    try {
      const response = await api.get(`/organizer-requests`, {
        params: {
          records: 9999999999,
        },
      });

      return response.data.data.map((user) => ({
        Name: user.name,
        Company: user.company,
        Position: user.title,
        "Date Created": formatDate(user.created_at),
        Email: user.email,
        Phone: user.phone,
        Status: user.review_status,
        Venues: (
          <span
            onClick={() => {
              setDisplayVenues(user?.requested_venues || [])
              setVenuesModal(true)
            }}
              style={{ color: "rgba(167, 41, 245, 1)", cursor: 'pointer', textDecoration: 'underline' }}
          >
              {user?.requested_venues?.length}
          </span>
        ),
        "Last Updated": formatDate(user.updated_at),
        id: user._id,
      }));
    } catch (error) {
      console.log(`Error fetching all data: ${error}`);
      return [];
    }
  };

  const columns = [
    "Name",
    "Company",
    "Position",
    "Date Created",
    "Email",
    "Phone",
    "Venues",
    "Status",
    "Last Updated",
    ""
  ];

  const sortableColumns = ["Name", "Company", "Status"];


  const handleChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("ListCurrentPageOrganizerReqs", page);
  };

  useEffect(() => {
    const storedPage = localStorage.getItem("ListCurrentPageOrganizerReqs");
    setCurrentPage(Number(storedPage) || 1);
  }, []);

  const sortColumnsMapping = {
    Name: "Name",
    Company: "Company",
    Status: "Status",
  };

  const handleSort = (columnName) => {
    const backendColumnName = sortColumnsMapping[columnName];
    if (sortBy === backendColumnName) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(backendColumnName);
      setSortDirection("asc");
    }
  };

  return (
    <>
      

        {
          (venuesModal && displayVenues.length) ? (
            <section className='delete-modal'>
              <article className='delete-modal__modal'>
                  <div className='delete-modal__modal-top'>
                      Requested Venues
                      <img style={{cursor: 'pointer'}} onClick={() => setVenuesModal(false)} src={exit} alt='white x'></img>
                  </div>
                  <div className='delete-modal__modal-content'>
                      <div>
                        {
                          displayVenues.map((i,k) => (
                            <div key={k} style={{
                              display: 'flex',
                              gap: 8,
                              marginBottom: 20
                            }}>
                              <div style={{minWidth: 20}}>
                                <MdMap size={20} />
                              </div>
      
                              <div>
                                <div style={{
                                  fontSize: 15,
                                  marginBottom: 4
                                }}>{i.name}</div>
                                <div style={{
                                  opacity: 0.8,
                                  fontSize: 14,
                                  fontWeight: 300
                                }}>{i.address}</div>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                  </div>
              </article>
            </section>
          ) : (<></>)
        }

        <TableColumn
          title="Organizer Requests"
          hasHeader={true}
          columns={columns}
          data={users}
          sortableColumns={sortableColumns}
          hasExport={true}
          hasSearch={true}
          // actions={actions}
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          records={records}
          // customButton={
          //   <button onClick={() => navigate("/users/add")}>
          //     Add new user +
          //   </button>
          // }
          inputSearchProps={{ value: searchTerm, onChange: handleChange }}
          fetchAllData={fetchAllData}
          onSort={handleSort}
        />
    </>
  );
};

export default OrganizerRequests;
