import "./Venues.scss";

import { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import SideBar from "../../components/SideBar/SideBar";
import TableColumn from "../../components/TableColumn/TableColumn";
import TopNav from "../../components/TopNav/TopNav";
import api from "../../services/api";

function Venues() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [venues, setVenues] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    Number(localStorage.getItem("ListCurrentPageBusiness")) || 1
  );
  const records = 10;

  const [searchTerm, setSearchTerm] = useState("");

  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("null");

  const getCurrentDayHours = (hours) => {
    const days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    const todayIndex = new Date().getDay();

    if (todayIndex !== -1 && hours && hours.hasOwnProperty(days[todayIndex])) {
      const today = days[todayIndex];
      const from = formatTime(hours[today].from);
      const to = formatTime(hours[today].to);
      const formattedDay = today.charAt(0).toUpperCase() + today.slice(1);
      return `${formattedDay} ${from}–${to}`;
    } else {
      return "N/A";
    }
  };

  useEffect(() => {
    const getVenues = async (page) => {
      try {
        const url = searchTerm ? `/venues/search?name=${searchTerm}` : `/venues`;
        const response = await api.get(url, {
          params: {
            records: records,
            page: page,
            sort_column: sortBy,
            sort: sortDirection,
          },
        });
        const formattedVenues = response.data.data.map((venue) => ({
          Name: venue.name,
          id: venue._id,
          "Working time": getCurrentDayHours(venue.hours),
          Location: venue.address,
          About: venue.description,
          Website: venue.url,
          Followers: venue.followers,
          "Check Ins": venue.check_ins,
        }));

        console.log(formattedVenues)

        setVenues(formattedVenues);
        setTotalPages(response.data.total);
      } catch (error) {
        console.log(`venues get request didn't work. error: ${error}`);
      } finally {
        setLoading(false);
      }
    }

    getVenues(currentPage)
  }, [navigate, currentPage, sortBy, sortDirection, searchTerm]);


  const fetchAllData = async () => {
    try {
      const response = await api.get(`/venues`, {
        params: {
          records: 9999999999,
        },
      });


      return response.data.data.map((venue) => ({
        Name: venue.name,
        id: venue._id,
        "Working time": getCurrentDayHours(venue.hours),
        Location: venue.address,
        About: venue.description,
        Website: venue.url,
        Followers: venue.followers,
        "Check Ins": venue.check_ins,
      }));

    } catch (error) {
      console.log(`Error fetching all data: ${error}`);
      return [];
    }
  };


  const columns = [
    "Name",
    "Working time",
    "Location",
    "Website",
    // "Price",
    "About",
    "Followers",
    "Check Ins",
  ];

  const formatTime = (time) => {
    const hour = Math.floor(time / 60);
    const minute = time % 60;
    const period = hour >= 12 ? "pm" : "am";
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    const formattedMinute = minute < 10 ? `0${minute}` : minute;
    return `${formattedHour}:${formattedMinute}${period}`;
  };

  const sortableColumns = ["Name", "Website", "Location"];

  const actions = [
    {
      label: <MdEdit size={30} color="rgba(167, 41, 245, 1)" />,
      onClick: (rowData) => {
        console.log(rowData);
        navigate(`/business/edit/${rowData.id}`);
      },
    },
  ];



  const handleChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setCurrentPage(0);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("ListCurrentPageBusiness", page);
  };



  useEffect(() => {
    const storedPage = localStorage.getItem("ListCurrentPageBusiness");

    setCurrentPage(Number(storedPage) || 1);
  }, []);


  const sortColumnsMapping = {
    Name: "name",
    Website: "url",
    Location: "address",
  };

  const handleSort = (columnName) => {
    const backendColumnName = sortColumnsMapping[columnName];
    if (sortBy === backendColumnName) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(backendColumnName);
      setSortDirection("asc");
    }
  };


  return (
    <main>
      <TopNav />
      <div className="main-container">
        <SideBar />
        <article
          style={{ width: "100%", display: "flex", justifyContent: "center", overflow: "hidden" }}
        >
          <section className="venues">
            <TableColumn
              title="Business"
              columns={columns}
              data={venues}
              sortableColumns={sortableColumns}
              hasExport={true}
              hasSearch={true}
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              records={records}
              actions={actions}
              customButton={
                <button
                  className="add-business"
                  onClick={() => navigate("/business/add")}
                >
                  Add new business +
                </button>
              }
              inputSearchProps={{ value: searchTerm, onChange: handleChange }}
              fetchAllData={fetchAllData}


              onSort={handleSort}

            />
          </section>
        </article>
      </div>
    </main>
  );
}

export default Venues;
