import React from "react";

import {
  ContainerTextArea,
  ErrorMessage,
  Label,
  StyledTextArea,
} from "./styles";

const TextAreaForm = ({ value, onChange, label, placeholder, error }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <ContainerTextArea>
      <div>
        {label && <Label>{label}</Label>}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </div>
      <StyledTextArea
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </ContainerTextArea>
  );
};

export default TextAreaForm;
