import React from "react";

import SelectForm from "../../../../../components/SelectForm/SelectForm";
import { ContainerAddAnotherVenue, ContainerAddVenue } from "../styles";

const TotalForDurationEvent = ({
  venues,
  handleSelectedVenue,
  handleRemoveVenue,
  handleAddNewVenue,
  error,
  venueOptions,
  disabled = false,
}) => {
  const getAvailableOptions = (selectedValues) => {
    return venueOptions.filter(
      (option) => !selectedValues.includes(option.value)
    );
  };

  const selectedVenueIds = venues
    .map((venue) => venue.selectedValue?.value)
    .filter(Boolean);

  return (
    <>
      {venues.map((venue, index) => (
        <ContainerAddAnotherVenue>
          <div key={venue.id}>
            <SelectForm
              label={`Venue #${index + 1}`}
              options={getAvailableOptions(selectedVenueIds)}
              value={venue.selectedValue}
              onChange={(value) => handleSelectedVenue(venue.id, value)}
              error={error}
              onRemove={() => handleRemoveVenue(venue.id)}
            />
          </div>
        </ContainerAddAnotherVenue>
      ))}

      <ContainerAddVenue>
        <button
          type="button"
          onClick={handleAddNewVenue}
          disabled={disabled || venues.length >= 10}
        >
          Add Another Venue
        </button>
      </ContainerAddVenue>
    </>
  );
};

export default TotalForDurationEvent;
