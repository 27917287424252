import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import TableColumn from "../../../components/TableColumn/TableColumn";
import api from "../../../services/api";
import TopNav from "../../../components/TopNav/TopNav";
import SideBar from "../../../components/SideBar/SideBar";

const ReviewTicketList = () => {
  const { eventsId } = useParams();

  const navigate = useNavigate();

  const [listReviewTicketList, setListReviewTicketList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    Number(localStorage.getItem("ListCurrentReviewTicketSales")) || 1
  );
  const records = 10;

  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const columns = [
    "Date",
    "Name",
    "Birthday",
    "Gender",
    "Instagram",
    "Status",
    "Vouchers Used",
    "Vouchers Left",
  ];

  const sortableColumns = ["Date", "Name", "Birthday", "Gender"];

  useEffect(() => {
    const storedPage = localStorage.getItem("ListCurrentReviewTicketSales");

    setCurrentPage(Number(storedPage) || 1);
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("ListCurrentReviewTicketSales", page);
  };

  const sortColumnsMapping = {
    Name: "name",
    Date: "date",
    Birthday: "",
    Gender: "",
  };

  const handleSort = (columnName) => {
    const backendColumnName = sortColumnsMapping[columnName];
    if (sortBy === backendColumnName) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(backendColumnName);
      setSortDirection("asc");
    }
  };

  useEffect(() => {
    const fetchData = async (page) => {
      try {
        const response = await api.get(`/tickets/list/${eventsId}`, {
          params: {
            page: page,
            records: 10,
            sort_column: sortBy,
            sort: sortDirection,
          },
        });

        const itemsTableColumns = response.data.data.map((review) => ({
          id: review._id,
          user_id: review?.user_id,
          Date: review?.created_at,
          Name: review?.user_name,
          Birthday: review?.user_birthday,
          Gender: review?.user_gender,
          Instagram: review?.user_ig,
          Status: review?.status,
          "Vouchers Used": review?.vouchers_used,
          "Vouchers Left": review?.vouchers_left,
        }));

        setListReviewTicketList(itemsTableColumns);
        setTotalPages(response.data.total);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData(currentPage);
  }, [sortBy, sortDirection, currentPage, eventsId]);

  // const actions: Action[] = [
  //   {
  //     label: <IoMdEye size={30} color="rgba(167, 41, 245, 1)" />,
  //     onClick: (rowData) => {
  //       navigate(`/review-guest-list-request/${rowData?.user_id}/${rowData?.id}`);
  //     },
  //   },
  // ];

  return (
    <>
      <main>
        <TopNav />

        <div className="main-container">
          <SideBar />

          <article
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <section className="past">
              <TableColumn
                title="Review Ticket sales"
                records={records}
                // actions={actions}
                columns={columns}
                data={listReviewTicketList}
                sortableColumns={sortableColumns}
                hasExport={true}
                hasSearch={true}
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                // fetchAllData={fetchAllData}
                onSort={handleSort}
              />
            </section>
          </article>
        </div>
      </main>
    </>
  );
};

export default ReviewTicketList;
