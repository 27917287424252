import "./AddEvent.scss";
import "react-toastify/dist/ReactToastify.css";

import { format } from "date-fns";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import * as Yup from "yup";

import checkMark from "../../assets/images/icons/check-mark.svg";
import chevronLeft from "../../assets/images/icons/chevron-left.svg";
import CustomDatePicker from "../../components/CustomDatePicker";
import { HoursOfOperation } from "../../components/HoursOfOperation";
import Image from "../../components/Image";
import InputForm from "../../components/InputForm/InputForm";
import SelectForm from "../../components/SelectForm/SelectForm";
import SideBar from "../../components/SideBar/SideBar";
import TextAreaForm from "../../components/TextAreaForm";
import TopNav from "../../components/TopNav/TopNav";
import api from "../../services/api";
import {
  ContainerButtons,
  ContainerForm,
  ContainerHoursOfOperation,
  ContainerImages,
  ContainerTextArea,
} from "./styles";

function AddEvent() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [category, setCategory] = useState([]);
  const [dressCode, setDressCode] = useState("");

  const [hours, setHours] = useState("");

  const [location, setLocation] = useState("");
  const [music, setMusic] = useState([]);
  const [price, setPrice] = useState("");
  const [venueImages, setVenueImages] = useState([{ id: v4() }]);
  const [about, setAbout] = useState("");
  const [specials, setSpecials] = useState("");

  const [errors, setErrors] = useState({});

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [dressCodeOptions, setDressCodeOptions] = useState([]);
  const [musicOptions, setMusicOptions] = useState([]);
  const [locationsOptions, setLocationOptions] = useState([]);

  const [listVenues, setListVenues] = useState([]);
  const [loadingVenues, setLoadingVenues] = useState(true);

  const [selectedVenues, setSelectedVenues] = useState(null);

  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const [, setFilters] = useState();

  useEffect(() => {
    async function getFilters() {
      try {
        const response = await api.get("/filters");

        const tags = response?.data?.tags;
        const dressCode = response?.data?.dress_code;
        const music = response?.data?.music;
        const location = response?.data?.location;

        const formattedTags = tags.map((tag) => ({
          value: tag,
          label: tag,
        }));
        const formattedDressCode = dressCode.map((tag) => ({
          value: tag,
          label: tag,
        }));
        const formattedMusic = music.map((tag) => ({
          value: tag,
          label: tag,
        }));
        const formattedLocation = location.map((tag) => ({
          value: tag,
          label: tag,
        }));

        setCategoryOptions(formattedTags);
        setDressCodeOptions(formattedDressCode);
        setMusicOptions(formattedMusic);
        setLocationOptions(formattedLocation);

        setFilters(response.data);
      } catch (err) {
        console.log(err);
      }
    }

    getFilters();
  }, []);

  const handleImageAdd = useCallback((event) => {
    const file = event.target.files?.[0];

    if (file) {
      const image = {
        id: v4(),
        file,
      };

      setVenueImages((oldState) => [...oldState, image]);
    }
  }, []);

  const handleImageRemove = useCallback((id) => {
    setVenueImages((oldState) => {
      const updatedState = oldState.filter((img) => img.id !== id);
      return updatedState;
    });
  }, []);

  const handleCategoryChange = (value) => {
    let selectedValues = [];

    if (Array.isArray(value)) {
      selectedValues = value.map((option) => option.value.toString());
    } else if (value !== null) {
      selectedValues = [value.value.toString()];
    }

    setCategory(selectedValues);
  };

  useEffect(() => {
    setLoadingVenues(true);

    const getVenues = async () => {
      try {
        const response = await api.get(`/venues`, {
          params: {
            records: 99999999,
            page: 1,
          },
        });

        setListVenues(response.data.data);
        setLoadingVenues(false);
      } catch (error) {
        console.log(`venues get request didn't work. error: ${error}`);
        setLoadingVenues(false);
      }
    };

    getVenues();
  }, []);

  const optionsVenus = loadingVenues
    ? []
    : listVenues.map((venue) => ({
        value: venue._id,
        label: venue.name,
      }));

  const handleCategoryVenues = (selectedOptions) => {
    setSelectedVenues(selectedOptions);
  };

  const handleLocationChange = (selectedOptions) => {
    setLocation(selectedOptions ? selectedOptions.value : "");
  };

  const handleDressChange = (selectedValues) => {
    setDressCode(selectedValues ? selectedValues.value : "");
  };

  const handleMusicChange = (value) => {
    let selectedValues = [];

    if (Array.isArray(value)) {
      selectedValues = value.map((option) => option.value.toString());
    } else if (value !== null) {
      selectedValues = [value.value.toString()];
    }

    setMusic(selectedValues);
  };

  const handleChangeAbout = (value) => {
    setAbout(value);
  };
  const handleChangeSpecials = (value) => {
    setSpecials(value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const validateFields = useCallback(async (data) => {
    const shape = Yup.object().shape({
      name: Yup.string().required('Required field!'),
      tags: Yup.array().required('Required field!').min(1, 'Select at least one tag option!'),
      venue_id: Yup.string().required('Required field!'),
      price: Yup.string().required('Required field!'),
      date: Yup.string().required('Required field!'),
      hours: Yup
        .object({
          from: Yup.number().typeError('Invalid hour selection!').required('Required field!'),
          to: Yup.number().typeError('Invalid hour selection!').required('Required field!'),
        })
        .required('Required field!'),
      dress: Yup.string().required('Required field!'),
      music: Yup.array().required('Required field!').min(1, 'Select at least one music option!'),
      location: Yup.string().required('Required field!'),
      description: Yup.string(),
      specials: Yup.string(),
    });

    await shape.validate(data, { abortEarly: false });
  }, []);

  const handleAddNewEvent = async (event) => {
    event.preventDefault();
    try {
      const formattedDate = selectedDate
        ? format(selectedDate, "yyyy-MM-dd")
        : "";

      const newEvents = {
        name: name,
        tags: category,
        venue_id: selectedVenues?.value,
        price: price,
        date: formattedDate,
        hours: hours.hours,
        dress: dressCode,
        music: music,
        location: location,
        description: about,
        specials: specials,
      };

      const addEvent = async () => {
        try {
          setLoading(true);

          await validateFields(newEvents);

          const { data: addedEvent } = await api.post("/events", newEvents);

          try {
            const formData = new FormData();

            venueImages.forEach((img) => {
              if (img.file) formData.append(`images`, img.file);
            });

            await api.post(`/events/images/${addedEvent._id}`, formData);

            toast.success("Event Added successfully!");
          } catch (error) {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            )
              toast.error(`${error.response.data.message}`);

            setLoading(false);

            if (error instanceof Yup.ValidationError) {
              const errorObj = error.inner.reduce((errorObj, currentError) => {
                const path = currentError.path || "unknown";
                return {
                  ...errorObj,
                  [path]: currentError.message,
                };
              }, {});

              setErrors(errorObj);
            }
          }

          navigate("/events");
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          )
            toast.error(`${error.response.data.message}`);

          setLoading(false);
          if (error instanceof Yup.ValidationError) {
            const errorObj = error.inner.reduce((errorObj, currentError) => {
              const path = currentError.path || "unknown";
              return {
                ...errorObj,
                [path]: currentError.message,
              };
            }, {});

            toast('Validation error: check the fields and try again!', { type: 'info' });

            setErrors(errorObj);
          }
        }
      };

      addEvent();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <main>
      <TopNav />
      <div className="main-container">
        <SideBar />
        <article
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <section className="add-event">
            <div className="add-event__top">
              <Link to="/events">
                <img
                  src={chevronLeft}
                  className="add-event__top-icon"
                  alt="chevron pointing left"
                ></img>
              </Link>
              <h1 className="add-event__top-heading">Adding new event</h1>
            </div>

            <ContainerForm onSubmit={handleAddNewEvent}>
              <div>
                <div>
                  <InputForm
                    label="Name"
                    id="Name"
                    onChange={(event) => setName(event.target.value)}
                    value={name}
                    placeholder="Enter name"
                    error={errors?.name}
                  />

                  <SelectForm
                    isMulti
                    onChange={handleCategoryChange}
                    options={categoryOptions}
                    error={errors?.tags}
                    value={
                      category
                        ? categoryOptions.filter((option) =>
                            category.includes(option.value)
                          )
                        : []
                    }
                    label="Category"
                  />
                </div>

                <div>
                  <SelectForm
                    isLoading={loadingVenues}
                    onChange={handleCategoryVenues}
                    options={optionsVenus}
                    value={selectedVenues}
                    label="Venue"
                    error={errors?.venue_id}
                  />

                  <SelectForm
                    onChange={handleDressChange}
                    options={dressCodeOptions}
                    error={errors?.dress}
                    value={
                      dressCode
                        ? dressCodeOptions.filter((option) =>
                            dressCode.includes(option.value)
                          )
                        : []
                    }
                    label="Dress-Code"
                  />
                </div>

                <div>
                  <CustomDatePicker
                    label="Date"
                    error={errors?.date}
                    selectedDate={selectedDate}
                    onChange={handleDateChange}
                  />

                  <SelectForm
                    onChange={handleLocationChange}
                    options={locationsOptions}
                    error={errors?.location}
                    value={
                      location
                        ? locationsOptions.filter((option) =>
                            location.includes(option.value)
                          )
                        : []
                    }
                    label="Location"
                  />
                </div>

                <div>
                  <ContainerHoursOfOperation>
                    <div style={{ gap: 0 }}>
                      <label>Hours of operation</label>
                      <p style={{ color: 'red', fontSize: '0.8rem' }}>{errors?.hours || errors['hours.to'] || errors['hours.from'] || ''}</p>
                    </div>
                    <div>
                      <HoursOfOperation
                        selectDay={false}
                        onChangeTime={({ value, type }) => {
                          setHours((oldState) => {
                            const newState = { ...oldState };

                            if (!newState.hours) {
                              newState.hours = {};
                            }

                            newState.hours[type] = value;

                            return newState;
                          });
                        }}
                      />
                    </div>
                  </ContainerHoursOfOperation>

                  <SelectForm
                    onChange={handleMusicChange}
                    options={musicOptions}
                    isMulti
                    error={errors?.music}
                    value={
                      music
                        ? musicOptions.filter((option) =>
                            music.includes(option.value)
                          )
                        : []
                    }
                    label="Music"
                  />
                </div>

                <div>
                  <InputForm
                    label="Price"
                    id="Price"
                    error={errors?.price}
                    onChange={(event) => setPrice(event.target.value)}
                    value={price}
                    placeholder="Enter Price"
                  />
                </div>

                <ContainerImages>
                  {venueImages.map((img) => {
                    return (
                      <Image
                        key={img.id}
                        onAdd={handleImageAdd}
                        onRemove={() => handleImageRemove(img.id)}
                      />
                    );
                  })}
                </ContainerImages>

                <ContainerTextArea>
                  <TextAreaForm
                    onChange={handleChangeAbout}
                    value={about}
                    label="About"
                    placeholder="Description of the venue"
                    error={errors?.description}
                  />
                </ContainerTextArea>

                <ContainerTextArea>
                  <TextAreaForm
                    onChange={handleChangeSpecials}
                    value={specials}
                    label="More Information (Specials)"
                    placeholder="Description of the venue"
                    error={errors?.specials}
                  />
                </ContainerTextArea>
              </div>

              <ContainerButtons>
                <button
                  type="submit"
                  disabled={loading}
                  style={{ opacity: loading ? 0.5 : 1 }}
                >
                  Create event
                  <img src={checkMark} alt="" />
                </button>
              </ContainerButtons>
            </ContainerForm>
          </section>
        </article>
      </div>
    </main>
  );
}

export default AddEvent;
