import React, { useCallback } from "react";
import { ErrorMessage, TimeInput, TimePickerContainer } from "./styles";

const TimePicker = ({ onChangeTime, label, error, defaultValue }) => {
  const handleOnChange = useCallback(
    (event) => {
      if (onChangeTime) {
        const { value } = event.target;
        let hoursPart = value;
        let periodPart;

        if (value.indexOf(" ") !== -1) {
          const [first, second] = value.split(" ");

          hoursPart = first;
          periodPart = second;
        }

        const [hours, minutes] = hoursPart
          .split(":")
          .map((item) => Number(item));
        let parsedHours = hours;

        if (periodPart === "PM" && hours < 12) {
          parsedHours += 12;
        } else if (periodPart === "AM" && hours === 12) {
          parsedHours = 0;
        }

        const hoursToMinutes = parsedHours * 60;

        onChangeTime({
          value: hoursToMinutes + minutes,
        });
      }
    },
    [onChangeTime]
  );

  return (
    <TimePickerContainer>
      <div>
        {label && <label>{label}</label>}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </div>

      <TimeInput
        type="time"
        onChange={handleOnChange}
        defaultValue={defaultValue}
      />
    </TimePickerContainer>
  );
};

export default TimePicker;
