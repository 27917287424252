import { auth } from '../../firebase/firebaseConfig';
import api from '../../services/api';
import InputForm from '../InputForm/InputForm';
import './PrivacySettings.scss';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    getAuth,
    updatePassword,
    EmailAuthProvider,
    reauthenticateWithCredential,
    User,
} from "firebase/auth";

import { useCallback, useEffect, useState } from 'react';

function PrivacySettings() {
    const [user, setUser] = useState();
    const [isLocationChecked, setIsLocationChecked] = useState(false);
    const [isTwoFactorChecked, setIsTwoFactorChecked] = useState(false);

    useEffect(() => {
        async function loadUser() {
            const { uid } = auth.currentUser;

            const { data } = await api.get(`/users/${uid}`);

            setUser(data.user);
            setIsTwoFactorChecked(data.user.two_factor_auth);
            setIsLocationChecked(data.user.location_sharing);
        }

        loadUser();
    }, []);

    const handleUpdateTwoFactorAuth = useCallback(async checked => {
        await api.put(`/users/${user.id}`, { two_factor_auth: checked });

        setIsTwoFactorChecked(checked);
    }, [user]);

    const handleUpdateLocationSharing = useCallback(async checked => {
        await api.put(`/users/${user.id}`, { location_sharing: checked });

        setIsLocationChecked(checked);
    }, [user]);

    const [passwords, setPasswords] = useState({
        newPassword: "",
        confirmPassword: "",
        currentPassword: "",
    });

    const handleChange = (event) => {
        const { id, value } = event.target;
        setPasswords((prevState) => ({ ...prevState, [id]: value }));
    };

    const handlePasswordChange = async () => {
        if (passwords.newPassword !== passwords.confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }

        try {
            const currentUser = auth.currentUser
            if (currentUser) {
                const email = currentUser.email;
                if (!email) {
                    toast.error("User does not have an associated email.");
                    return;
                }

                const credential = EmailAuthProvider.credential(
                    email,
                    passwords.currentPassword
                );

                await reauthenticateWithCredential(currentUser, credential);
                await updatePassword(currentUser, passwords.newPassword);
                toast.success("Password updated successfully");
                setPasswords({
                    newPassword: "",
                    confirmPassword: "",
                    currentPassword: "",
                });
            } else {
                toast.error("No authenticated user");
            }
        } catch (error) {
            console.error("Error updating password", error);
            if (error instanceof Error && error.message) {
                if (error.message === "auth/wrong-password") {
                    toast.error("Current password is incorrect");
                } else {
                    toast.error("Error updating password: " + error.message);
                }
            } else {
                toast.error("Error updating password");
            }
        }
    };

    return (
        <section className='privacy'>
            <div className='privacy__field'>
                <p className='privacy__field-text'>
                    Location sharing
                </p>

                <div className={`slide-button ${isLocationChecked ? 'checked' : ''}`} onClick={() => handleUpdateLocationSharing(!isLocationChecked)}>
                    <div className="slider"></div>
                </div>
            </div>
            <div className='privacy__field'>
                <p className='privacy__field-text'>
                    Two-factor authentication
                </p>

                <div className={`slide-button ${isTwoFactorChecked ? 'checked' : ''}`} onClick={() => handleUpdateTwoFactorAuth(!isTwoFactorChecked)}>
                    <div className="slider"></div>
                </div>
            </div>


            <div className='container-password'>
                <div className='change-password'>
                    <h1>Change password</h1>
                </div>

                <InputForm
                    id="currentPassword"
                    onChange={handleChange}
                    value={passwords.currentPassword}
                    label="Current password"
                    type="password"
                />

                <InputForm
                    id="newPassword"
                    onChange={handleChange}
                    value={passwords.newPassword}
                    label="New password"
                    type="password"
                />

                <InputForm
                    id="confirmPassword"
                    onChange={handleChange}
                    value={passwords.confirmPassword}
                    label="Confirm password"
                    type="password"
                />

                <button className='button-password'
                    onClick={handlePasswordChange}
                >
                    Save
                </button>
            </div >
        </section>
    )
}

export default PrivacySettings;
