import React from "react";
import SelectForm from "../../../../../components/SelectForm/SelectForm";
import { ContainerAddAnotherVenue, ContainerAddVenue } from "../styles";

const CustomPerVenue = ({
  venues,
  handleSelectedVenue,
  handleRemoveVenue,
  handleAddNewVenue,
  handleVoucherQuantityChange,
  error,
  venueOptions,
  selectedOptionsDrinkVouchers,
  customVouchersQuantity,
  disabled = false,
}) => {
  const generateVoucherOptions = (max) => {
    const options = Array.from({ length: max }, (_, index) => ({
      value: index + 1,
      label: `${index + 1}`,
    }));

    options.splice(0, 0, { value: 0, label: 0 });

    return options;
  };

  const getAvailableOptions = (selectedValues) => {
    return venueOptions.filter(
      (option) => !selectedValues.includes(option.value)
    );
  };

  const selectedVenueIds = venues
    .map((venue) => venue.selectedValue?.value)
    .filter(Boolean);

  const calculateTotalVouchers = () => {
    const total = venues.reduce(
      (total, venue) => total + Number(venue.voucherQuantity?.value || 0),
      0
    );

    return total;
  };

  const maxVouchers = selectedOptionsDrinkVouchers?.value || 0;
  const totalVouchers = calculateTotalVouchers();
  const remainingVouchers = maxVouchers - totalVouchers;

  const handleVoucherQuantityUpdate = (id, newQuantity) => {
    if (newQuantity?.value <= maxVouchers) {
      handleVoucherQuantityChange(id, newQuantity);
    } else {
      alert(`You can add a maximum of ${maxVouchers} drink vouchers.`);
    }
  };

  return (
    <>
      <ContainerAddAnotherVenue>
        {venues.map((venue, index) => {
          const venueMaxVouchers = Math.max(
            0,
            maxVouchers - (totalVouchers - (venue.voucherQuantity?.value || 0))
          );

          return (
            <div key={venue.id}>
              <SelectForm
                label={`Venue #${index + 1}`}
                options={getAvailableOptions(selectedVenueIds)}
                value={venue.selectedValue}
                onChange={(value) => handleSelectedVenue(venue.id, value)}
                error={error}
              />
              <SelectForm
                label="Drink Voucher Qty"
                options={generateVoucherOptions(venueMaxVouchers)}
                value={venue.voucherQuantity}
                onChange={(value) =>
                  handleVoucherQuantityUpdate(venue.id, value)
                }
                error={error}
                onRemove={() => handleRemoveVenue(venue.id)}
              />
            </div>
          );
        })}
      </ContainerAddAnotherVenue>
      <ContainerAddVenue>
        <button
          type="button"
          onClick={handleAddNewVenue}
          disabled={disabled || venues.length >= 10}
        >
          Add Another Venue
        </button>
      </ContainerAddVenue>
    </>
  );
};

export default CustomPerVenue;
