import { FirebaseError } from 'firebase/app';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import logoZona from '../../assets/images/icons/logo.svg';
import InputForm from '../../components/InputForm/InputForm';
import { handleSignIn, resetPassword } from '../../firebase/firebaseAuth';
import api from '../../services/api';
import { Container, ContainerForgotPassword, ContainerInputs, ErrorMessage } from './styles';

const SignIn = () => {
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const loginSchema = yup.object().shape({
        email: yup.string().email("Invalid email").required("Email is required"),
        password: yup.string().required("Password is required"),
    });

    const forgotPassword = yup.object().shape({
        email: yup.string().email("Invalid email").required("Email is required"),
    });

    const handleLogin = async (e) => {
        e.preventDefault();
        setErrors({});
        try {
            await loginSchema.validate({ email, password }, { abortEarly: false });
            await handleSignIn(email, password);
            navigate("/moderators");
        } catch (error) {
            console.log(error);
            if (error instanceof yup.ValidationError) {
                const validationErrors = {};
                error.inner.forEach((err) => {
                    if (err.path) {
                        validationErrors[err.path] = err.message;
                    }
                });
                setErrors(validationErrors);
            } else if (error instanceof FirebaseError) {
                if (error.code === "auth/invalid-credential") {
                    setErrors({
                        ...errors,
                        credentialInvalid: "Invalid email or password",
                    });
                } else {
                    setErrors({ ...errors, firebase: error.message });
                    console.error("Error Invalid Credential:", error);
                }
            } else {
                console.error("Unknown error:", error);
            }
        }
    };

    const handleForgotPassword = async () => {
        setErrors({});
        try {
            if (!email) {
                setErrors({ email: "Required to reset password" });
                return;
            }

            await forgotPassword.validate({ email }, { abortEarly: false });

            const { data } = await api.get(`/users/email/${email}`);

            if (!data.in_use) {
                setErrors({ email: "Email not found in database" });
                return;
            }


            await resetPassword(email);
            toast.success(
                "Password recovery email sent successfully! Check your inbox."
            );

            setErrors({});
        } catch (error) {
            if (error instanceof yup.ValidationError) {
                setErrors({ email: error.message });
            } else {
                console.error("Error sending password recovery email:", error);
                toast.error("Error sending password recovery email. Please try again.");
            }
        }
    };



    return (
        <>
            <Container>
                <ContainerInputs>
                    <div>
                        <img src={logoZona} alt="Logo Zona" />
                    </div>


                    <div>
                        <InputForm
                            placeholder="Enter e-mail"
                            label="E-mail"
                            onChange={(event) => setEmail(event.target.value)}
                            id="email"
                            value={email}
                            error={errors.email}
                        />

                        <InputForm
                            type="password"
                            placeholder="Enter password"
                            label="Password"
                            onChange={(event) => setPassword(event.target.value)}
                            id="password"
                            value={password}
                            error={errors.password}
                        />



                        <button
                            onClick={handleLogin}
                            backgroundColor="linear-gradient(271.14deg, #7229F5 1.5%, #0A3FD6 98.6%)"
                        >
                            Continue
                        </button>


                        <ContainerForgotPassword>
                            <ErrorMessage>
                                {errors.credentialInvalid && (
                                    <span>{errors.credentialInvalid}</span>
                                )}
                            </ErrorMessage>

                            <button onClick={handleForgotPassword}>Forgot password</button>
                        </ContainerForgotPassword>
                    </div>

                </ContainerInputs>
            </Container>
        </>
    )
}

export default SignIn;