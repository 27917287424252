import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { MdEdit } from "react-icons/md";
import TableColumn from "../../../components/TableColumn/TableColumn";

const PastAdvertising = () => {
  const navigate = useNavigate();
  const sorts = ["Client", "status"];
  const headings = [
    "Time",
    "Client",
    "Location",
    "Website",
    "About",
    "Status",
    "",
  ];
  const [ads, setAds] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    Number(localStorage.getItem("ListCurrentPageAdvertising")) || 1
  );
  const records = 10;

  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("null");

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  useEffect(() => {
    const fetchData = async (page, searchTerm) => {
      try {
        const url = searchTerm ? `/ads?name=${searchTerm}` : `/ads`;

        const response = await api.get(url, {
          params: {
            page: page,
            records: records,
            sort_column: sortBy,
            sort: sortDirection,
          },
        });

        const now = new Date().getTime();

        const formattedEvents = response.data.data
          .filter((item) => item.date_end < now)
          .map((item) => ({
            Time: `${formatDate(item.date_start)} - ${formatDate(
              item.date_end
            )}`,
            Client: item.venue_name || (
              <span style={{ color: "red" }}>Client not informed</span>
            ),
            Location: item.location,
            Website: item.website,
            About: item.about,
            Status: item.status,
            _id: item._id,
          }));

        setTotalPages(response.data.total);
        setAds(formattedEvents);
      } catch (error) {
        console.log(`Ads get request didn't work. error: ${error}`);
      }
    };
    fetchData(currentPage, searchTerm);
  }, [sortBy, sortDirection, currentPage, searchTerm]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("ListCurrentPageAdvertising", page);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const sortColumnsMapping = {
    Name: "name",
    Category: "tags",
    Music: "music",
  };

  const handleSort = (columnName) => {
    const backendColumnName = sortColumnsMapping[columnName];
    if (sortBy === backendColumnName) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(backendColumnName);
      setSortDirection("asc");
    }
  };

  const actions = [
    {
      label: <MdEdit size={30} color="rgba(167, 41, 245, 1)" />,
      onClick: (rowData) => {
        navigate(`/ad-management/edit/${rowData._id}`);
      },
    },
  ];

  const fetchAllData = async () => {
    try {
      const response = await api.get(`/ads`, {
        params: {
          records: 1000,
        },
      });
      return response.data.data.map((item) => ({
        Client: item?.business_name,
        Location: item.event_name,
        Website: item?.website,
        About: item?.about,
        Status: item?.status,
        _id: item._id,
      }));
    } catch (error) {
      console.log(`Error fetching all data: ${error}`);
      return [];
    }
  };

  return (
    <section className="past-ads">
      <TableColumn
        hasHeader={true}
        columns={headings}
        data={ads}
        sortableColumns={sorts}
        hasExport={true}
        hasSearch={true}
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        records={records}
        actions={actions}
        inputSearchProps={{ value: searchTerm, onChange: handleChange }}
        fetchAllData={fetchAllData}
        onSort={handleSort}
        hasModifyHeader={true}
      />
    </section>
  );
};

export default PastAdvertising;
