import { ClipLoader } from 'react-spinners';
import './LoadingPage.scss'

const LoadingPage = () => {
    return (
        <div className='container-loading'>
            <ClipLoader color="#A729F5" size={50} />
        </div>

    )
}

export default LoadingPage;