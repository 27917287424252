import zIndex from '@mui/material/styles/zIndex';
import { MdOutlineCancel } from 'react-icons/md';
import Select from 'react-select';
import { ContainerOnRemove } from './styles';

const SelectForm = ({ options, value, onChange, label, error, isDisabled, onRemove = false, ...props }) => {
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: 'none',
            backgroundColor: isDisabled ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255, 255, 255, 0.1)',
            borderRadius: '8px',
            width: '100%',
            padding: '4px 16px',
            boxShadow: 'none',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            opacity: isDisabled ? 0.6 : 1,
            minHeight: "54px"
        }),
        input: (provided) => ({
            ...provided,
            color: isDisabled ? 'rgba(255, 255, 255, 0.5)' : 'rgba(255, 255, 255, 1)',
            background: 'transparent',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: isDisabled ? 'rgba(255, 255, 255, 0.5)' : 'rgba(255, 255, 255, 1)',
            background: 'transparent',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'blue' : isDisabled ? 'rgba(255, 255, 255, 0.2)' : 'white',
            color: state.isSelected ? 'white' : 'black',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            borderRadius: '4px',
            color: 'white',
            padding: '2px 6px',
        }),
        MultiValueGeneric: (provided) => ({
            ...provided,
            color: "white"
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: 'white',
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0',
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 10,
            cursor: isDisabled ? 'not-allowed' : 'pointer',
        }),
        container: (provided) => ({
            ...provided,
            width: "100%"
        })
    };

    // const handleSelectChange = (selectedOption) => {
    //     onChange(selectedOption.value);
    // };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
            <div style={{ display: 'flex', alignItems: 'center', padding: "0px 16px", gap: "10px" }}>
                {label && <label style={{ color: '#fff', fontSize: "14px" }}>{label}</label>}
                {error && <p style={{ color: 'red', fontSize: '0.8em' }}>{error}</p>}
            </div>
            <ContainerOnRemove>
                <Select
                    {...props}
                    options={options}
                    value={value}
                    onChange={onChange}
                    styles={customStyles}
                    isDisabled={isDisabled}
                />

                {onRemove && (
                    <button
                        type="button"
                        onClick={onRemove}
                    >
                        <MdOutlineCancel size={24} color="rgba(247, 6, 6, 1)" />
                    </button>
                )}
            </ContainerOnRemove>
        </div>

    );
}

export default SelectForm;
