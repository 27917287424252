import "./EditAd.scss";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Calendar from "react-calendar";
import { useNavigate, useParams } from "react-router-dom";
import { v4 } from "uuid";

import bin from "../../assets/images/icons/delete.svg";
import checkMark from "../../assets/images/icons/check-mark.svg";
import chevronLeft from "../../assets/images/icons/chevron-left.svg";
import Image from "../../components/Image";
import InputForm from "../../components/InputForm/InputForm";
import SideBar from "../../components/SideBar/SideBar";
import TopNav from "../../components/TopNav/TopNav";
import api from "../../services/api";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectForm from "../../components/SelectForm/SelectForm";
import { format } from 'date-fns';
import parseDate from "../../utils/parseDate";

const EditAd = () => {
    const navigate = useNavigate();
    const { adID } = useParams();
    const [ad, setAd] = useState({});
    const [adToDelete, setAdToDelete] = useState({});
    const [showCalendar, setShowCalendar] = useState(false);
    const [showEndCalendar, setShowEndCalendar] = useState(false);
    const calendarComponentRef = useRef(null);
    const endCalendarComponentRef = useRef(null);

    const [businessName, setBusinessName] = useState();
    const [location, setLocation] = useState();
    const [website, setWebsite] = useState();
    const [dateStart, setDateStart] = useState();
    const [dateEnd, setDateEnd] = useState();
    const [about, setAbout] = useState();

    const [errors, setErrors] = useState({});

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getAd = async () => {
            try {
                const response = await api.get(`/ads/${adID}`);


                setAd(response.data);
            } catch (err) {
                console.log(`unable to get single ad. this was the error: ${err}`);
            }
        };
        getAd();
    }, [adID]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                calendarComponentRef.current &&
                !calendarComponentRef.current.contains(event.target)
            ) {
                setShowCalendar(false);
            }
            if (
                endCalendarComponentRef.current &&
                !endCalendarComponentRef.current.contains(event.target)
            ) {
                setShowEndCalendar(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const formatDate = (date) => {
        if (!date) return "";

        const d = new Date(date),
            day = "" + d.getDate(),
            month = "" + (d.getMonth() + 1),
            year = d.getFullYear();

        return [day.padStart(2, "0"), month.padStart(2, "0"), year].join("/");
    };

    function handleDeleteAd(id) {
        const deleteAd = async () => {
            try {
                await api.delete(`/ads/${adToDelete}`);
                navigate("/ad-management");
            } catch (err) {
                console.log(`failed to delete ad. this is the error: ${err}`);
            }
        };

        deleteAd();
    }

    const handleDateStartChange = (nextValue) => {
        setDateStart(nextValue);
        setShowCalendar(false);
    };

    const handleDataEndChange = (nextValue) => {
        setDateEnd(nextValue);
        setShowEndCalendar(false);
    };

    const toTimestamp = (date) => {
        if (!date) return null;
        const d = new Date(date);
        return d.getTime();
    };

    const validationSchema = Yup.object().shape({
        // businessName: Yup.string().required("Business name is required"),
        // location: Yup.string().required("Location is required"),
        // website: Yup.string().required("Website is required"),
        // dateStart: Yup.string().required("Date Start is required"),
        // dateEnd: Yup.string().required("Date End is required"),
        // about: Yup.string().required("About is required"),
    });

    const handleAddingAd = async (event) => {
        event.preventDefault();

        try {
            await validationSchema.validate(
                {
                    // businessName,
                    // location,
                    // website,
                    // dateStart,
                    // dateEnd,
                    // about,
                },
                { abortEarly: false }
            );

            const formattedDateStart = dateStart
                ? format(dateStart, "yyyy-MM-dd")
                : "";

            const formattedDateEnd = dateEnd
                ? format(dateEnd, "yyyy-MM-dd")
                : "";


            const newAd = {
                business_name: businessName,
                location: selectedVenues?.value,
                event: selectedEvents?.value,
                website: website,
                date_start: formattedDateStart,
                date_end: formattedDateEnd,
                about: about,
            };

            console.log(newAd);

            await api.put(`/ads/${adID}`, newAd);

            toast.success('Ad Edit successfully!');

            navigate("/ad-management");
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const validationErrors = {};
                error.inner.forEach((err) => {
                    validationErrors[err.path] = err.message;
                });
                setErrors(validationErrors);
                toast.error(`Error Edit Ad. Please try again, ${error.message}`);

            } else {
                console.error("Error adding user:", error);
            }
        }
    };

    useEffect(() => {
        setBusinessName(ad.business_name);
        setLocation(ad.website);
        setWebsite(ad.website);
        setImages(ad.photo_url);
        setAbout(ad.about);

        setDateStart(parseDate(ad.date_start))
        setDateEnd(parseDate(ad.date_end))
    }, [ad]);

    const handleDelete = async () => {
        try {
            await api.delete(`/ads/${adID}`);
            toast.success('Ad Delete successfully!');

            navigate("/ad-management");
        } catch (err) {
            console.log(`failed to delete ads. this is the error: ${err}`);
        }
    };

    const [images, setImages] = useState(null);

    const handleImageAdd = useCallback(
        async (events) => {
            if (!ad?._id) return;

            const file = events.target.files[0];

            const formData = new FormData();

            formData.append("photo", file);

            const { data } = await api.post(`/ads/photo/${ad._id}`, formData);

            // const recentImageAdded = data.photo;

            // const image = { id: recentImageAdded };

            setImages(data.photo);

            alert("Image uploaded successfully!");
        },
        [ad?._id]
    );

    const handleImageRemove = async () => {
        if (!ad?._id) return;

        await api.delete(`/ads/photo/${ad._id}`);

        setImages(null);

        alert("Image DELETED successfully!");
    };


    const [loadingVenuesAndEvents, setLoadingVenuesAndEvents] = useState(true);
    const [listVenues, setListVenues] = useState([]);
    const [listEvents, setListEvents] = useState([]);
    const [selectedVenues, setSelectedVenues] = useState(null);
    const [selectedEvents, setSelectedEvents] = useState(null);

    useEffect(() => {
        setLoadingVenuesAndEvents(true);

        const fetchData = async () => {
            try {
                const [venuesResponse, anotherResponse] = await Promise.all([
                    api.get("/venues", {
                        params: {
                            records: 99999999,
                            page: 1,
                        },
                    }),
                    api.get("/events", {
                        params: {
                            records: 99999999,
                            page: 1,
                        },
                    }),
                ]);

                setListVenues(venuesResponse.data.data);
                setListEvents(anotherResponse.data.data);

                setLoadingVenuesAndEvents(false);
            } catch (error) {
                console.log(`API requests didn't work. Error: ${error}`);
                setLoadingVenuesAndEvents(false);
            }
        };

        fetchData();
    }, []);

    const optionsVenus = listVenues.map((venue) => ({
        value: venue._id,
        label: venue.name,
    }));

    const optionsEvents = listEvents.map((venue) => ({
        value: venue._id,
        label: venue.name,
    }));

    const handleCategoryVenues = (selectedOptions) => {
        setSelectedVenues(selectedOptions);
    };

    const handleCategoryEvents = (selectedOptions) => {
        setSelectedEvents(selectedOptions);
    };


    const venueId = optionsVenus.find(
        (option) => option.value === ad.location
    );
    const eventsId = optionsEvents.find(
        (option) => option.value === ad.event
    );

    useEffect(() => {
        if (venueId && !selectedVenues) {
            setSelectedVenues(venueId);
        }
        if (eventsId && !selectedEvents) {
            setSelectedEvents(eventsId);
        }
    }, [venueId, selectedVenues, selectedEvents, eventsId]);


    return (
        <main>
            <TopNav />
            <div className="main-container">
                <SideBar />

                <article
                    style={{ width: "100%", display: "flex", justifyContent: "center" }}
                >
                    <section className="add-ad">
                        <div className="container-header">
                            <div className="add-ad__top-left">
                                <img
                                    src={chevronLeft}
                                    className="add-ad__top-left-icon"
                                    onClick={() => navigate(-1)}
                                    alt=""
                                ></img>
                                <h1 className="add-ad__top-left-heading">Edit ad</h1>
                            </div>

                            <button onClick={handleDelete} className="edit-venue__top-button">
                                <p className="edit-venue__top-button-text">Delete Ad</p>
                                <img src={bin} alt="white garbage bin"></img>
                            </button>
                        </div>

                        <form onSubmit={handleAddingAd} className="add-ad__form">
                            <section>
                                <div>
                                    <div>
                                        <InputForm
                                            placeholder="Enter Business Name"
                                            label="Business Name"
                                            type="text"
                                            id="Business Name"
                                            name="Business Name"
                                            value={businessName}
                                            onChange={(event) => setBusinessName(event.target.value)}
                                            error={errors.businessName}
                                        />
                                    </div>

                                    <div>
                                        <SelectForm
                                            onChange={handleCategoryVenues}
                                            options={optionsVenus}
                                            value={selectedVenues}
                                            error={errors.selectedVenues}
                                            label="Venue"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div>
                                        <SelectForm
                                            isLoading={loadingVenuesAndEvents}
                                            onChange={handleCategoryEvents}
                                            options={optionsEvents}
                                            value={selectedEvents}
                                            error={errors.selectedEvents}
                                            label="Events"
                                        />
                                    </div>

                                    <div>
                                        <InputForm
                                            placeholder="Enter Website"
                                            label="Website"
                                            type="text"
                                            id="website"
                                            name="website"
                                            value={website}
                                            onChange={(event) => setWebsite(event.target.value)}
                                            error={errors.website}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div className="add-event__form-block-group">
                                        <div
                                            className="edit-event__form-block-group-container"
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "start",
                                            }}
                                        >
                                            <label style={{ padding: "0.5vw 2vw", color: "#fff" }}>
                                                Date start
                                            </label>
                                            <input
                                                style={{ width: "100%" }}
                                                type="text"
                                                id="eventDate"
                                                name="eventDate"
                                                placeholder="DD/MM/YYYY"
                                                className="edit-event__form-block-group-input"
                                                value={dateStart ? formatDate(dateStart) : ""}
                                                onChange={(event) => setDateStart(event.target.value)}
                                                onFocus={() => setShowCalendar(true)}
                                            />
                                        </div>
                                        <div className="edit-user__form-first-field-input-calendar">
                                            {showCalendar && (
                                                <Calendar
                                                    onChange={handleDateStartChange}
                                                    next2Label={null}
                                                    prev2Label={null}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="add-event__form-block-group">
                                        <div
                                            className="edit-event__form-block-group-container"
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "start",
                                            }}
                                        >
                                            <label style={{ padding: "0.5vw 2vw", color: "#fff" }}>
                                                Date end
                                            </label>
                                            <input
                                                style={{ width: "100%" }}
                                                type="text"
                                                id="eventDate"
                                                name="eventDate"
                                                placeholder="DD/MM/YYYY"
                                                className="edit-event__form-block-group-input"
                                                value={dateEnd ? formatDate(dateEnd) : ""}
                                                onChange={(event) => setDateEnd(event.target.value)}
                                                onFocus={() => setShowEndCalendar(true)}
                                            />

                                            <div>
                                                {showEndCalendar && (
                                                    <Calendar
                                                        onChange={handleDataEndChange}
                                                        next2Label={null}
                                                        prev2Label={null}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <Image
                                        onRemove={handleImageRemove}
                                        defaultValue={images}
                                        onAdd={handleImageAdd}
                                    />
                                </div>

                                <div>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <label style={{ color: "#fff", padding: "0.5vw 2vw" }}>
                                            About
                                        </label>
                                        <textarea
                                            rows={4}
                                            id="venueDescription"
                                            name="about"
                                            value={about}
                                            onChange={(event) => setAbout(event.target.value)}
                                            placeholder="Enter about"
                                            className="venue-info__form-group-about-input"
                                        />
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className="add-user__form-button"
                                    disabled={loading}
                                    style={{ opacity: loading ? 0.5 : 1 }}
                                >
                                    <p className="add-user__form-button-text">Save Information</p>
                                    <img src={checkMark} alt="a checkmark"></img>
                                </button>
                            </section>
                        </form>
                    </section>
                </article>
            </div>
        </main>
    );
}

export default EditAd;
