import { useEffect, useState } from "react";
import "./VenueCheckIns.scss";
import api from "../../services/api";
import { useParams } from "react-router-dom";
import TableColumn from "../TableColumn/TableColumn";

const VenueCheckIns = () => {
    const { venueId } = useParams();

    const [listVenues, setListVenues] = useState();

    const [totalPages, setTotalPages] = useState(0);
    const records = 10;

    console.log(totalPages)

    const [currentPage, setCurrentPage] = useState(
        Number(localStorage.getItem("ListCurrentPageBusinessCheckIns")) || 1
    );

    const columns = [
        "Name",
        "Birthday",
        "Email",
        "Gender",
        "Check In Date",
    ];

    const [searchTerm, setSearchTerm] = useState("");

    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState("null");

    useEffect(() => {
        const getListVenues = async (page) => {
            try {

                // const url = searchTerm ? `/check-ins/venues/?name=${searchTerm}` : `/check-ins/venues/`;


                const response = await api.get(`/check-ins/venues/${venueId}`, {
                    params: {
                        page: page,
                        records: records,
                        sort_column: sortBy,
                        sort: sortDirection,
                    }
                });
                const formattedCheckIns = response.data.data.map((item) => {
                    return {
                        "Check In Date": item.check_in_date,
                        "Birthday": item.user_birthday,
                        "Email": item.user_email,
                        "Gender": item.user_gender,
                        "Name": item.user_name
                    }
                });

                setListVenues(formattedCheckIns);
                setTotalPages(response.data.total);

            } catch (err) {
                console.log(`error fetching Venues. This is error: ${err}`);
            }
        };

        getListVenues(currentPage);
    }, [venueId, sortBy, sortDirection, currentPage]);

    const sortColumnsMapping = {
        Name: "name",
        Website: "url",
        Location: "address",
    };

    const handleSort = (columnName) => {
        const backendColumnName = sortColumnsMapping[columnName];
        if (sortBy === backendColumnName) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortBy(backendColumnName);
            setSortDirection("asc");
        }
    };


    const handleChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        setCurrentPage(0);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        localStorage.setItem("ListCurrentPageBusinessCheckIns", page);
    };

    const sortableColumns = ["Name", "Email"];


    useEffect(() => {
        setCurrentPage(0);
    }, [searchTerm]);

    return (
        <>
            <TableColumn
                title="Check Ins"
                hasHeader={true}
                columns={columns}
                data={listVenues}
                sortableColumns={sortableColumns}
                hasExport={true}
                // hasSearch={true}
                // actions={actions}
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                records={records}
                inputSearchProps={{ value: searchTerm, onChange: handleChange }}
                // fetchAllData={fetchAllData}
                onSort={handleSort}
            />
        </>
    )
};

export default VenueCheckIns;
