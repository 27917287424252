import './TopNav.scss';

import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase/firebaseConfig';

import logo from '../../assets/images/icons/logo.svg';
import profileIcon from '../../assets/images/icons/profile-icon.svg';
import signoutIcon from '../../assets/images/icons/logout.svg';
import chevronRight from '../../assets/images/icons/chevron-right.svg';

function TopNav() {
    const navigate = useNavigate();

    const [signoutModal, setSignoutModal] = useState(false);

    const signoutModalRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (signoutModalRef.current && !signoutModalRef.current.contains(event.target)) {
                setSignoutModal(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    function handleSignout() {
        console.log('over here')
        auth.signOut()
            .then(() => {
                console.log('successful signout'); navigate('/signin')
            }).catch((err) => {
                console.log(`error signing out. this is the error: ${err}`)
            })

    }

    return (
        <section className='topnav'>

            <img className='topnav__logo' src={logo} alt='Zona official logo'></img>

            <div className='topnav__right'>
                <img
                    onClick={() => setSignoutModal(!signoutModal)}
                    className='topnav__right-user'
                    src={profileIcon}
                    alt='small circle with person'
                ></img>
            </div>
            {
                signoutModal &&
                <div ref={signoutModalRef} onClick={() => handleSignout()} className='topnav__signout'>
                    <div className='topnav__signout-left'>
                        <img className='topnav__signout-icon' src={signoutIcon} alt='small book with an arrow pointing right'></img>
                        <p className='topnav__signout-left-text'>
                            Log Out
                        </p>
                    </div>
                    <img className='topnav__signout-icon' src={chevronRight} alt='chevron pointing right'></img>
                </div>
            }
        </section>
    )
}

export default TopNav;