import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import EventsPast from '../../components/EventsPast/EventsPast';
import EventsUpcoming from '../../components/EventsUpcoming/EventsUpcoming';
import SideBar from '../../components/SideBar/SideBar';
import TopNav from '../../components/TopNav/TopNav';
import ListBarCrawlEvent from './NewBarCrawlEvent/List';

const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const ToggleButton = styled.button`
  background: linear-gradient(271.14deg, #7229f5 1.5%, #0a3fd6 98.6%);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 207px;
  padding: 16px 24px;
  font-size: 18px;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff1a;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 207px;
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 5px;
  backdrop-filter: blur(20px);
`;

const DropdownItem = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  background: transparent;
  color: #ffff;
  width: 100%;
  text-align: start;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const TabsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const Tab = styled.div`
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 1vw 0;
  text-decoration: none;
  width: 50%;
  border-bottom: ${props => props.active ? '1px solid #a729f5' : 'none'};
  user-select: none;
  cursor: pointer;

  > p  {
    font-size: 14px;

  }
`;

export const ButtonCustom = styled.button`
    background: linear-gradient(271.14deg, #7229f5 1.5%, #0a3fd6 98.6%);
    color: #ffffff;
    font-size: 1.1rem;
    font-weight: 400;
    border-radius: 8px;
    border: none;
    display: flex;
    justify-content: space-between;
    padding: 16px 24px 16px 24px;
    cursor: pointer;
`

function Events() {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('upcomingEvents');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const savedTab = localStorage.getItem("eventsSelectedTab");
    if (savedTab) {
      setActiveTab(savedTab);
    }
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    localStorage.setItem("eventsSelectedTab", tab);
  };

  return (
    <main>
      <TopNav />
      <div className="main-container">
        <SideBar />
        <article
          style={{ width: "100%", display: "flex", justifyContent: "center", overflow: "hidden" }}
        >
          <section className="events">
            <div className="events__top">
              <h1 className="events__top-heading">Events</h1>
              <div className="events__top-buttons">
                <DropdownWrapper ref={dropdownRef}>
                  <ToggleButton onClick={toggleDropdown}>Add new event +</ToggleButton>
                  {isDropdownOpen && (
                    <DropdownMenu>
                      <DropdownItem onClick={() => navigate("/events/add")}>
                        Create Event
                      </DropdownItem>
                      <DropdownItem onClick={() => navigate("/bar-crawl-event/new")}>
                        Create Featured Event
                      </DropdownItem>
                    </DropdownMenu>
                  )}
                </DropdownWrapper>


              </div>
            </div>

            <div>
              <TabsContainer>
                <Tab
                  active={activeTab === 'upcomingEvents'}
                  onClick={() => handleTabClick('upcomingEvents')}
                >
                  <p>Upcoming Events</p>
                </Tab>
                <Tab
                  active={activeTab === 'pastEvents'}
                  onClick={() => handleTabClick('pastEvents')}
                >
                  <p>Past Events</p>
                </Tab>
                <Tab
                  active={activeTab === 'barCrawl'}
                  onClick={() => handleTabClick('barCrawl')}
                >
                  <p>Featured Events</p>
                </Tab>
              </TabsContainer>
            </div>

            {activeTab === 'upcomingEvents' && <EventsUpcoming />}
            {activeTab === 'pastEvents' && <EventsPast />}
            {activeTab === 'barCrawl' && <ListBarCrawlEvent />}
          </section>
        </article>
      </div>
    </main>
  );
}

export default Events;
