import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, isValid } from 'date-fns';

const CustomDatePicker = ({ timestamp, onDateChange }) => {
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        if (timestamp && !isNaN(timestamp)) {
            setSelectedDate(new Date(timestamp));
        } else {
            setSelectedDate(null);
        }
    }, [timestamp]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        if (onDateChange) {
            if (date) {
                onDateChange(date.getTime()); 
            } else {
                onDateChange(null);
            }
        }
    };

    const formatDateForInput = (date) => {
        return format(date, 'MM/dd/yyyy');
    };

    return (
        <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="MM/dd/yyyy"
            placeholderText="MM/DD/YYYY"
            className="edit-event__form-block-group-input"
            isClearable
        />
    );
};

export default CustomDatePicker;
