
import "./styles.scss"
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FormDatePicker = ({ onBirthdaySelect, defaultDate }) => {
  const [birthday, setBirthday] = useState(defaultDate || null);

  const handleBirthdayChange = (date) => {
    setBirthday(date);
    if (date) {
      const formattedDate = formatDate(date);
      onBirthdaySelect(formattedDate);
    } else {
      onBirthdaySelect("");
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    setBirthday(defaultDate || null);
  }, [defaultDate]);

  return (
    <div className="container-formdate">
      <label htmlFor="birthday">Birthday</label>
      <DatePicker
        id="birthday"
        selected={birthday}
        onChange={handleBirthdayChange}
        placeholderText="YYYY-MM-DD"
        dateFormat="yyyy-MM-dd"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
    </div>
  );
};

export default FormDatePicker;
