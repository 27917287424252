import './DeleteModal.scss';

import exit from '../../assets/images/icons/exit.svg';

function DeleteModal({ handleDelete, setDeleteModal, modToDelete, title, message, tableName, button, isLoading }) {

    return (
        <section className='delete-modal'>
            <article className='delete-modal__modal'>
                <div className='delete-modal__modal-top'>
                    {title}
                    <img onClick={() => setDeleteModal(false)} src={exit} alt='white x'></img>
                </div>
                <div className='delete-modal__modal-content'>
                    {message || (
                        <>
                            <p className='delete-modal__modal-content-text'>
                                ARE YOU SURE YOU WANT TO DELETE
                            </p>
                            <p className='delete-modal__modal-content-text'>
                                {modToDelete.firstName} {modToDelete.lastName}
                            </p>
                            <p className='delete-modal__modal-content-text'>
                                FROM THE {tableName} TABLE?
                            </p>
                        </>
                    )}
                </div>
                <div className='container-buttons-modal'>
                    <button onClick={() => setDeleteModal(false)} >
                        Cancel
                    </button>
                    <button
                        onClick={() => handleDelete(modToDelete.id)}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Deleting...' : (button || 'Delete')}
                    </button>
                </div>
            </article>
        </section>
    )
}

export default DeleteModal;