import styled from "styled-components";

export const ContainerForm = styled.form`
  > div {
    display: flex;
    flex-direction: column;

    gap: 32px;

    > div {
      display: flex;
      align-items: center;
      gap: 20px;

      width: 100%;

      > div {
        width: 100%;
      }
    }
  }
`;

export const ContainerHoursOfOperation = styled.div`
  display: flex;
  flex-direction: column;

  gap: 6px;

  label {
    color: rgb(255, 255, 255);

    padding: 0px 16px;
    font-size: 14px;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 20px;

    > div:nth-child(1) {
      padding: 4px 16px;
    }
  }
`;

export const ContainerAddress = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5vw;

  > label {
    color: rgb(255, 255, 255);
    font-weight: bold;

    padding: 0px 16px;
  }
`;

export const ContainerHeader = styled.div`
  align-items: center;
  display: flex;

  gap: 2.5vw;

  margin-bottom: 47px;

  > h1 {
    color: #fff;
    font-size: 2rem;
    font-weight: 700;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: start;

  gap: 20px;

  height: auto;

  margin-top: 20px;

  > button {
    background: linear-gradient(90deg, #7229f5, #0a3fd6);
    align-items: center;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    max-width: 245px;
    padding: 1vw;

    color: #fff;

    gap: 10px;
  }
`;

export const ContainerImages = styled.div`
  max-width: 100%;

  > div {
    max-width: 100%;

    width: 172px !important;
  }
`;

export const ContainerTextArea = styled.div``;
