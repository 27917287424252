import "./AdManagement.scss";

import SideBar from "../../components/SideBar/SideBar";
import TopNav from "../../components/TopNav/TopNav";
import CurrentAds from "../../components/CurrentAds/CurrentAds";
import PastAds from "../../components/PastAds/PastAds";
import { Link } from "react-router-dom";

import upload from "../../assets/images/icons/upload.svg";

import { useEffect, useState } from "react";
import api from "../../services/api";
import CurrentAdvertising from "./CurrentAdvertising/CurrentAdvertising";
import PastAdvertising from "./PastAdvertising/PastAdvertising";

function AdManagement() {
    const [currentTab, setCurrentTab] = useState(true);
    const [pastTab, setPastTab] = useState(false);

    function handleSubNavClick(tab) {
        switch (tab) {
            case "current":
                setPastTab(false);
                setCurrentTab(true);
                break;
            case "past":
                setCurrentTab(false);
                setPastTab(true);
                break;
            default:
                break;
        }
    }

    return (
        <main>
            <TopNav />
            <div className="main-container">
                <SideBar />
                <article
                    style={{ width: "100%", display: "flex", justifyContent: "center", overflow: "hidden" }}
                >
                    <section className="ad-management">
                        <div className="ad-management__top">
                            <h1 className="ad-management__top-heading">Ad Management</h1>

                            <div style={{ display: "flex", gap: "20px" }}>
                                <Link
                                    to="/ad-management/add"
                                    className="events__top-buttons-colorful"
                                >
                                    Create new Ad +
                                </Link>
                            </div>
                        </div>

                        <section className="ad-management__tabs">
                            <div
                                onClick={() => handleSubNavClick("current")}
                                className={
                                    currentTab
                                        ? "ad-management__tabs-link ad-management__tabs-link--active"
                                        : "ad-management__tabs-link"
                                }
                            >
                                <p className="ad-management__tabs-link-text">
                                    Current advertising
                                </p>
                            </div>

                            <div
                                onClick={() => handleSubNavClick("past")}
                                className={
                                    pastTab
                                        ? "ad-management__tabs-link ad-management__tabs-link--active"
                                        : "ad-management__tabs-link"
                                }
                            >
                                <p className="ad-management__tabs-link-text">
                                    Past advertising
                                </p>
                            </div>
                        </section>

                        {currentTab ? <CurrentAdvertising /> : <PastAdvertising />}
                    </section>
                </article>
            </div>
        </main>
    );
}

export default AdManagement;
