import React, { useEffect, useState, useCallback } from "react";
import { MdOutlineRemoveRedEye, MdEdit, MdCheck, MdCancel, MdPin, MdMap } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import exit from '../../assets/images/icons/exit.svg';
import SideBar from "../../components/SideBar/SideBar";
import TopNav from "../../components/TopNav/TopNav";
import TableColumn from "../../components/TableColumn/TableColumn";
import api from "../../services/api";
import "./MembershipRequests.scss";
import { format } from "date-fns";
import { ButtonActions } from "../../components/TableColumn/styles";

const MembershipRequests = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    Number(localStorage.getItem("ListCurrentPageMembershipReqs")) || 1
  );

  const records = 10;
  const [searchTerm, setSearchTerm] = useState("");

  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("null");

  const formatDate = (dateString) => {
    return format(new Date(dateString), "MMMM d, yyyy");
  };

  const actions = [
    // {
    //   label: <MdCheck size={30} color="rgba(167, 41, 245, 1)" />,
    //   onClick: async (rowData) => {
    //     if (window.confirm("Are you sure you want to approve this request?")) {
    //       try {
    //         await api.post(`/organizer-requests/review/${rowData._id}`, {
    //           action: 'approve',
    //           reason: ''
    //         });
  
    //         window.location.reload();
    //       } catch (error) {
    //         console.log('Error Processing Request ==> ', error);
    //         alert('Error Processing Request')
    //       }
          
    //     }
    //   },
    // },
    // {
    //   label: <MdCancel size={30} color="red" />,
    //   onClick: async(rowData) => {
    //     let reason = prompt("Are you sure you want to deny this request? \n\n Please provide a reason below");
    //     if (!(reason == null)) {
    //       try {
    //         await api.post(`/organizer-requests/review/${rowData._id}`, {
    //           action: 'disapprove',
    //           reason: (reason || '')
    //         });
  
    //         window.location.reload();

    //       } catch (error) {
    //         console.log('Error Processing Request ==> ', error);
    //         alert('Error Processing Request');
    //       }
    //     }
    //   },
    // },
    {
      label: <MdOutlineRemoveRedEye size={30} color="rgba(167, 41, 245, 1)" />,
      onClick: (requestData) => {
        navigate(`/membership-requests/${requestData.requesterId}`, {
          state: {
            requestData
          }
        });
      },
    },
  ];

  const getUsers = useCallback(
    async (page) => {
      try {
        const response = await api.get(`/membership/search`, {
          params: {
            page: page,
            records: records,
            sort_column: sortBy,
            sort: sortDirection,
          },
        });

        console.log(response.data);
        

        const itemsTableColumns = response.data.data.map((user) => ({
          Name: (user?.requesterFirstName + " " + user?.requesterLastName),
          "Date Created": formatDate(user.created_at),
          Email: user?.requesterEmail,
          Phone: user?.requesterPhone,
          Status: user.reviewStatus,
          Plan: user?.subscriptionPlan,
          "Billing Cycle": user?.billingCycle,
          // Venues: (
          //   <span
          //     onClick={() => {
          //       setDisplayVenues(user?.requested_venues || [])
          //       setVenuesModal(true)
          //     }}
          //       style={{ color: "rgba(167, 41, 245, 1)", cursor: 'pointer', textDecoration: 'underline' }}
          //   >
          //       {user?.requested_venues?.length}
          //   </span>
          // ),
          "Last Updated": formatDate(user.updated_at),
          "": (
            <div>
              {actions.map((action, actionIndex) => (
                <ButtonActions
                  key={actionIndex}
                  onClick={() => action.onClick(user)}
                >
                  {action.label}
                </ButtonActions>
              ))}
            </div>
          ),
          id: user._id,
        }));

        setUsers(itemsTableColumns);
        setTotalPages(response.data.total);
      } catch (error) {
        console.error(`Error getting organizer requests. Error: ${error}`);
      }
    },
    [sortBy, sortDirection]
  );

  useEffect(() => {
    getUsers(currentPage, sortDirection);
  }, [currentPage, sortDirection, getUsers]);

  const fetchAllData = async () => {
    try {
      const response = await api.get(`/membership/search`, {
        params: {
          records: 9999999999,
          sort_column: sortBy,
          sort: sortDirection,
        },
      });

      return response.data.data.map((user) => ({
        Name: (user?.requesterFirstName + " " + user?.requesterLastName),
          "Date Created": formatDate(user.created_at),
          Email: user?.requesterEmail,
          Phone: user?.requesterPhone,
          Status: user.reviewStatus,
          Plan: user?.subscriptionPlan,
          "Billing Cycle": user?.billingCycle,
          "Last Updated": formatDate(user.updated_at),
          "": (
            <div>
              {actions.map((action, actionIndex) => (
                <ButtonActions
                  key={actionIndex}
                  onClick={() => action.onClick(user)}
                >
                  {action.label}
                </ButtonActions>
              ))}
            </div>
          ),
          id: user._id,
      }));
    } catch (error) {
      console.log(`Error fetching all data: ${error}`);
      return [];
    }
  };

  const columns = [
    "Name",
    "Date Created",
    "Email",
    "Phone",
    "Plan",
    "Billing Cycle",
    "Status",
    "Last Updated",
    ""
  ];

  const sortableColumns = ["Name", "Plan", "Billing Cycle"];


  const handleChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("ListCurrentPageMembershipReqs", page);
  };

  useEffect(() => {
    const storedPage = localStorage.getItem("ListCurrentPageMembershipReqs");
    setCurrentPage(Number(storedPage) || 1);
  }, []);

  const sortColumnsMapping = {
    Name: "Name",
    Company: "Plan",
    Status: "Billing Cycle",
  };

  const handleSort = (columnName) => {
    const backendColumnName = sortColumnsMapping[columnName];
    if (sortBy === backendColumnName) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(backendColumnName);
      setSortDirection("asc");
    }
  };

  return (
    <>

        <TableColumn
          title="VIP Membership Requests"
          hasHeader={true}
          columns={columns}
          data={users}
          sortableColumns={sortableColumns}
          hasExport={true}
          hasSearch={true}
          // actions={actions}
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          records={records}
          // customButton={
          //   <button onClick={() => navigate("/users/add")}>
          //     Add new user +
          //   </button>
          // }
          inputSearchProps={{ value: searchTerm, onChange: handleChange }}
          fetchAllData={fetchAllData}
          onSort={handleSort}
        />
    </>
  );
};

export default MembershipRequests;
