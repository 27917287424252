import { styled } from "styled-components";

export const TimePickerContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 6px;

  > div:nth-child(1) {
    display: flex;
    align-items: center;

    > label {
      display: flex;
      align-items: center;
      padding: 0px 16px;
      gap: 10px;
      color: rgb(255, 255, 255);
      font-weight: bold;
      font-size: 14px;
    }
  }
`;

export const TimeInput = styled.input`
  text-align: start;

  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 13px 16px;
  min-height: 54px;
  width: 100%;

  font-size: 14px;


  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    filter: invert(100%) sepia(0%) saturate(100%) hue-rotate(130deg)
      brightness(95%) contrast(80%);
  }
`;

export const TimeSelect = styled.select`
  padding: 8px;
  margin: 0 5px;

  background-color: rgba(255, 255, 255, 0.1);

  color: #fff;

  border: none;

  > option {
    color: #aaaa;
  }
`;

export const ErrorMessage = styled.p`
  color: #ff0000;
  font-size: 0.8em;
`;
