import './Queries.scss';

import { useEffect, useState } from 'react';

import SideBar from '../../components/SideBar/SideBar';
import TopNav from '../../components/TopNav/TopNav';
import UserQueries from './UserQueries/UserQueries';
import VenueQueries from './VenueQueries/VenueQueries';

const Queries = () => {
    const [userQueries, setUserQueries] = useState(true);
    const [venueQueries, setVenueQueries] = useState(false);


    useEffect(() => {
        const savedTab = localStorage.getItem("queriesSelectedTab");
        if (savedTab === "userQueries") {
            setUserQueries(true);
            setVenueQueries(false);
        } else {
            setUserQueries(false);
            setVenueQueries(true);
        }
    }, []);

    function handleSubNavClick(tab) {
        switch (tab) {
            case "userQueries":
                setVenueQueries(false);
                setUserQueries(true);
                localStorage.setItem("queriesSelectedTab", "userQueries");

                break;
            case "venueQueries":
                setUserQueries(false);
                setVenueQueries(true);
                localStorage.setItem("queriesSelectedTab", "venueQueries");

                break;
            default:
                break;
        }
    }
    return (
        <main>
            <TopNav />
            <div className="main-container">
                <SideBar />

                <article
                    style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        overflow: "hidden"
                    }}
                >
                    <section className="events">
                        <div className="events__top">
                            <h1 className="events__top-heading">Queries</h1>
                        </div>

                        <div className="events__tabs">
                            <div
                                onClick={() => handleSubNavClick("userQueries")}
                                className={
                                    userQueries
                                        ? "events__tabs-link events__tabs-link--active"
                                        : "events__tabs-link"
                                }
                            >
                                <p className="events__tabs-link-text">User queries</p>
                            </div>

                            <div
                                onClick={() => handleSubNavClick("venueQueries")}
                                className={
                                    venueQueries
                                        ? "events__tabs-link events__tabs-link--active"
                                        : "events__tabs-link"
                                }
                            >
                                <p className="events__tabs-link-text">Venue queries</p>
                            </div>
                        </div>

                        {userQueries ? (
                            <UserQueries />
                        ) : (
                            <VenueQueries />
                        )}
                    </section>
                </article>
            </div>
        </main>
    );
};

export default Queries;
