import './EditVenue.scss';

import { useEffect, useMemo, useState } from 'react';
import { FiCheck, FiX } from 'react-icons/fi';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import chevronLeft from '../../assets/images/icons/chevron-left.svg';
import SideBar from '../../components/SideBar/SideBar';
import TopNav from '../../components/TopNav/TopNav';
import VenueCheckIns from '../../components/VenueCheckIns/VenueCheckIns';
import VenueInformation from '../../components/VenueInformation/VenueInformation';
import api from '../../services/api';

const EditVenue = () => {
  const navigate = useNavigate();
  const { venueId } = useParams();
  const [searchParams, setUrlSearch] = useSearchParams();
  const moderatingVenue = useMemo(() => {
    return !!searchParams.get("moderating");
  }, [searchParams]);
  const [venueInfoActive, setVenueInfoActive] = useState(true);
  const [checkInInfoActive, setCheckInInfoActive] = useState(false);
  const [specVenue, setSpecVenue] = useState({});
  const [clickCount, setClickCount] = useState(0);

  useEffect(() => {
    const getVenue = async () => {
      try {
        const response = await api.get(`/venues/${venueId}`);
        setSpecVenue(response.data);
      } catch (err) {
        console.log(
          `error retreiving specific venue. this was the error: ${err}`
        );
      }
    };
    getVenue();
  }, [venueId]);

  const handleSubNavClick = (tab) => {
    switch (tab) {
      case "venueInfo":
        setCheckInInfoActive(false);
        setVenueInfoActive(true);
        break;
      case "checkIns":
        setVenueInfoActive(false);
        setCheckInInfoActive(true);
        break;
      default:
        break;
    }
  };

  const handleVenueActivation = async () => {
    try {
      if (clickCount === 1) {
        if (specVenue.status === "inactive") {
          await api.put(`/venues/${venueId}`, { status: "active" });
        } else {
          await api.delete(`/venues/${venueId}`);
        }
        setSpecVenue((prevSpecVenue) => ({
          ...prevSpecVenue,
          status: specVenue.status === "inactive" ? "active" : "inactive",
        }));
        setClickCount(0);
      } else {
        setClickCount((prevCount) => prevCount + 1);
      }
    } catch (err) {
      console.log(`error deleting users. this was the error: ${err}`);
    }
  };

  const handleVenueAcceptance = async () => {
    const requestId = searchParams.get("moderating");
    await api.put(`/venue-requests/accept/${requestId}`);
    setUrlSearch({});
    navigate("/moderators");
  };

  const handleVenueDeny = async () => {
    const requestId = searchParams.get("moderating");
    await api.put(`/venue-requests/deny/${requestId}`);
    setUrlSearch({});
    navigate("/moderators");
  };

  const [listStatusBusiness, setlistStatusBusiness] = useState();

  useEffect(() => {
    const getStatusBusinessRequest = async () => {
      try {
        const requestId = searchParams.get("moderating");
        const response = await api.get(`/venue-requests/${requestId}`);
        setlistStatusBusiness(response.data);
      } catch (err) {
        console.log(
          `error retreiving specific venue. this was the error: ${err}`
        );
      }
    };
    getStatusBusinessRequest();
  }, [searchParams]);

  return (
    <main>
      <TopNav />
      <div className="main-container">
        <SideBar />
        <article style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <section className="edit-venue">
            <div className="edit-venue__top">
              <div className="edit-venue__top-left">
                <img onClick={() => navigate(-1)} className="edit-venue__top-left-icon" src={chevronLeft} alt="arrowhead pointing left"></img>
                <h1 className="edit-venue__top-left-heading">Editing business</h1>
              </div>
              <div className="edit-venue__top-button-wrapper">
                {listStatusBusiness?.status === "denied" && moderatingVenue && (
                  <button onClick={handleVenueAcceptance} className="venue-action-button accept">
                    <p className="edit-venue__top-button-text" style={{ margin: 0 }}>Accept business request</p>
                  </button>
                )}
                {listStatusBusiness?.status === "accepted" && moderatingVenue && (
                  <button onClick={handleVenueDeny} className="venue-action-button deny">
                    <p className="edit-venue__top-button-text" style={{ margin: 0 }}>Deny business request</p>
                  </button>
                )}
                {listStatusBusiness?.status === 'pending' && (
                  <>
                    <button onClick={handleVenueDeny} className="venue-action-button deny">
                      <p className="edit-venue__top-button-text" style={{ margin: 0 }}>Deny business request</p>
                    </button>

                    <button onClick={handleVenueAcceptance} className="venue-action-button accept">
                      <p className="edit-venue__top-button-text" style={{ margin: 0 }}>Accept business request</p>
                    </button>
                  </>
                )}
                <div style={{
                  display: 'flex',
                  flexDirection: 'column-reverse',
                  gap: '10px'
                }}>
                  <button onClick={handleVenueActivation} className="edit-venue__top-button">
                    <p className="edit-venue__top-button-text">
                      {specVenue.status === "inactive" ? "Activate business" : "Deactivate business"}
                    </p>
                    {specVenue.status === "inactive" ? (
                      <FiCheck color="#fff" style={{ marginLeft: 8 }} />
                    ) : (
                      <FiX color="#fff" style={{ marginLeft: 8 }} />
                    )}
                  </button>
                  {clickCount === 1 && (
                    <p style={{ color: "red", fontSize: "12px", textAlign: "center" }}>Click one more time to confirm.</p>
                  )}
                </div>
              </div>
            </div>
            <div className="edit-venue__sub-nav">
              <div onClick={() => handleSubNavClick("venueInfo")} className={venueInfoActive ? "edit-venue__sub-nav-link edit-venue__sub-nav-link--active" : "edit-venue__sub-nav-link"}>
                <p className="edit-venue__sub-nav-link-text">Business Information</p>
              </div>
              <div onClick={() => handleSubNavClick("checkIns")} className={checkInInfoActive ? "edit-venue__sub-nav-link edit-venue__sub-nav-link--active" : "edit-venue__sub-nav-link"}>
                <p className="edit-venue__sub-nav-link-text">Check Ins</p>
              </div>
            </div>
            {venueInfoActive ? (
              <VenueInformation />
            ) : (
              <VenueCheckIns />
            )}
          </section>
        </article>
      </div>
    </main >
  );
}

export default EditVenue;