import React from "react";
import {
  ContainerAddAnotherVenue,
  ContainerAddVenue,
  RemoveVenue,
} from "../styles";
import SelectForm from "../../../../../components/SelectForm/SelectForm";

const TotalPerVenue = ({
  venues,
  handleSelectedVenue,
  handleRemoveVenue,
  handleAddNewVenue,
  handleVoucherQuantityChange,
  error,
  venueOptions,
  selectedOptionsDrinkVouchers,
  disabled = false,
}) => {
  const generateVoucherOptions = () => {
    return Array.from({ length: 10 }, (_, index) => ({
      value: index + 1,
      label: `${index + 1}`,
    }));
  };

  const getAvailableOptions = (selectedValues) => {
    return venueOptions.filter(
      (option) => !selectedValues.includes(option.value)
    );
  };

  const selectedVenueIds = venues
    .map((venue) => venue.selectedValue?.value)
    .filter(Boolean);

  const handleVoucherQuantityUpdate = (id, newQuantity) => {
    handleVoucherQuantityChange(id, newQuantity);
  };

  return (
    <>
      <ContainerAddAnotherVenue>
        {venues.map((venue, index) => {
          return (
            <div key={venue.id}>
              <SelectForm
                label={`Venue #${index + 1}`}
                options={getAvailableOptions(selectedVenueIds)}
                value={venue.selectedValue}
                onChange={(value) => handleSelectedVenue(venue.id, value)}
                error={error}
              />
              <SelectForm
                label="Drink Voucher Qty"
                options={generateVoucherOptions()}
                value={selectedOptionsDrinkVouchers}
                onChange={(value) =>
                  handleVoucherQuantityUpdate(venue.id, value)
                }
                error={error}
                onRemove={() => handleRemoveVenue(venue.id)}
                isDisabled={
                  selectedOptionsDrinkVouchers && selectedOptionsDrinkVouchers
                }
              />
            </div>
          );
        })}
      </ContainerAddAnotherVenue>

      <ContainerAddVenue>
        <button
          type="button"
          onClick={() => handleAddNewVenue()}
          disabled={disabled || venues.length >= 10}
        >
          Add Another Venue
        </button>
      </ContainerAddVenue>
    </>
  );
};

export default TotalPerVenue;
