import './LiquorSponsor.scss';

import SideBar from "../../components/SideBar/SideBar"
import TopNav from "../../components/TopNav/TopNav"

import upload from '../../assets/images/icons/upload.svg';

import { Link } from 'react-router-dom';

function LiquorSponsor() {
    return (
        <main>
            <TopNav />
            <section className="main-container">
                <SideBar />
                <article style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <section className="liquor">
                        <div className='liquor__top'>
                            <h1 className='liquor__top-heading'>
                                Liquor Sponsorship
                            </h1>

                            <div className='liquor__top-buttons'>
                                <Link to="/liquor" className="liquor__top-buttons-colorful">
                                    Add new sponsor +
                                </Link>

                                <button className='liquor__top-buttons-clear'>
                                    <p className='liquor__top-buttons-clear-text'>
                                        Export data
                                    </p>
                                    <img className='liquor__top-buttons-clear-icon' src={upload} alt='upload icon'></img>
                                </button>

                            </div>
                        </div>

                        <section className='liquor__tabs'>
                            <div>
                                <p className='liquor__tabs-link-text'>
                                    Current Sponsorship
                                </p>
                            </div>

                            <div>
                                <p className='liquor__tabs-link-text'>
                                    Past Sponsorship
                                </p>
                            </div>
                        </section>
                    </section>
                </article>
            </section>
        </main>
    )
}

export default LiquorSponsor
