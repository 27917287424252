import './AddAd.scss';
import 'react-toastify/dist/ReactToastify.css';

import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import * as Yup from 'yup';

import checkMark from '../../assets/images/icons/check-mark.svg';
import chevronLeft from '../../assets/images/icons/chevron-left.svg';
import Image from '../../components/Image';
import InputForm from '../../components/InputForm/InputForm';
import SelectForm from '../../components/SelectForm/SelectForm';
import SideBar from '../../components/SideBar/SideBar';
import TopNav from '../../components/TopNav/TopNav';
import api from '../../services/api';
import CustomDatePicker from "../../components/CustomDatePicker";


const AddAd = () => {
  const navigate = useNavigate();

  const [businessName, setBusinessName] = useState();
  const [location, setLocation] = useState();
  const [website, setWebsite] = useState();
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [about, setAbout] = useState();

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [showCalendar, setShowCalendar] = useState(false);
  const [showEndCalendar, setShowEndCalendar] = useState(false);

  const calendarComponentRef = useRef(null);
  const endCalendarComponentRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        calendarComponentRef.current &&
        !calendarComponentRef.current.contains(event.target)
      ) {
        setShowCalendar(false);
      }
      if (
        endCalendarComponentRef.current &&
        !endCalendarComponentRef.current.contains(event.target)
      ) {
        setShowEndCalendar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const validationSchema = Yup.object().shape({
    // businessName: Yup.string().required("Business name is required"),
    // selectedVenues: Yup.string().required("Venues is required"),
    // selectedEvents: Yup.string().required("Events is required"),
    // website: Yup.string().required("Website is required"),
    // dateStart: Yup.string().required("Date Start is required"),
    // dateEnd: Yup.string().required("Date end is required"),
    // about: Yup.string().required("About is required"),
  });

  const handleAddingAd = async (event) => {
    event.preventDefault();

    try {
      await validationSchema.validate(
        {
          // businessName,
          // selectedVenues: selectedVenues?.value,
          // selectedEvents: selectedEvents?.value,
          // website,
          // dateStart,
          // dateEnd,
          // about,
        },
        { abortEarly: false }
      );

      const formData = new FormData();

      promoterImg.forEach((img) => {
        if (img?.file && img.file instanceof Blob) {
          formData.append(`photo`, img.file);
        }
      });


      const formattedDateStart = dateStart
        ? format(dateStart, "yyyy-MM-dd")
        : "";

      const formattedDateEnd = dateEnd
        ? format(dateEnd, "yyyy-MM-dd")
        : "";


      const newAd = {
        business_name: businessName,
        location: selectedVenues?.value,
        event: selectedEvents?.value,
        website: website,
        date_start: formattedDateStart,
        date_end: formattedDateEnd,
        photo: promoterImg.length > 0 && promoterImg[1]?.file,
        about: about,
      };

      console.log(newAd);

      await api.post("/ads", newAd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setBusinessName("");
      setLocation("");
      setWebsite("");
      setDateStart("");
      setDateEnd("");
      setAbout("");

      toast.success("Ad Added successfully!");

      navigate("/ad-management");
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
        toast.error(`Error Added Ad. Please try again, ${error.message}`);
      } else {
        console.error("Error adding ad", error);
      }
    }
  };


  const [promoterImg, setPromoterImg] = useState([{ id: v4() }]);

  const handleImageAdd = useCallback((event) => {
    const file = event.target.files[0];

    const image = {
      id: v4(),
      file,
    };

    setPromoterImg((oldState) => [...oldState, image]);
  }, []);

  const handleImageRemove = useCallback((id) => {
    setPromoterImg((oldState) => {
      const updatedState = oldState.filter((img) => img.id !== id);

      return updatedState;
    });
  }, []);

  const [loadingVenuesAndEvents, setLoadingVenuesAndEvents] = useState(true);
  const [listVenues, setListVenues] = useState([]);
  const [listEvents, setListEvents] = useState([]);
  const [selectedVenues, setSelectedVenues] = useState(null);
  const [selectedEvents, setSelectedEvents] = useState(null);

  useEffect(() => {
    setLoadingVenuesAndEvents(true);

    const fetchData = async () => {
      try {
        const [venuesResponse, anotherResponse] = await Promise.all([
          api.get("/venues", {
            params: {
              records: 99999999,
              page: 1,
            },
          }),
          api.get("/events", {
            params: {
              records: 99999999,
              page: 1,
            },
          }),
        ]);

        setListVenues(venuesResponse.data.data);
        setListEvents(anotherResponse.data.data);

        setLoadingVenuesAndEvents(false);
      } catch (error) {
        console.log(`API requests didn't work. Error: ${error}`);
        setLoadingVenuesAndEvents(false);
      }
    };

    fetchData();
  }, []);

  const optionsVenus = loadingVenuesAndEvents
    ? []
    : listVenues.map((venue) => ({
      value: venue._id,
      label: venue.name,
    }));

  const optionsEvents = loadingVenuesAndEvents
    ? []
    : listEvents.map((venue) => ({
      value: venue._id,
      label: venue.name,
    }));

  const handleCategoryVenues = (selectedOptions) => {
    setSelectedVenues(selectedOptions);
  };

  const handleCategoryEvents = (selectedOptions) => {
    setSelectedEvents(selectedOptions);
  };


  const handleDateStartChange = (date) => {
    setDateStart(date);
  };
  const handleDateEndChange = (date) => {
    setDateEnd(date);
  };

  return (
    <main>
      <TopNav />
      <section className="main-container">
        <SideBar />
        <article
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <section className="add-ad">
            <div className="add-ad__top">
              <div className="add-ad__top-left">
                <img
                  src={chevronLeft}
                  className="add-ad__top-left-icon"
                  onClick={() => navigate(-1)}
                  alt=""
                ></img>
                <h1 className="add-ad__top-left-heading">Adding ad</h1>
              </div>
            </div>

            <form onSubmit={handleAddingAd} className="add-ad__form">
              <section>
                <div>
                  <div>
                    <InputForm
                      placeholder="Enter Business Name"
                      label="Business Name"
                      type="text"
                      id="Business Name"
                      name="Business Name"
                      value={businessName}
                      onChange={(event) => setBusinessName(event.target.value)}
                      error={errors.businessName}
                    />
                  </div>

                  <div>
                    <SelectForm
                      isLoading={loadingVenuesAndEvents}
                      onChange={handleCategoryVenues}
                      options={optionsVenus}
                      value={selectedVenues}
                      error={errors.selectedVenues}
                      label="Venue"
                    />
                  </div>
                </div>

                <div>
                  <div>
                    <SelectForm
                      isLoading={loadingVenuesAndEvents}
                      onChange={handleCategoryEvents}
                      options={optionsEvents}
                      value={selectedEvents}
                      error={errors.selectedEvents}
                      label="Events"
                    />
                  </div>

                  <div>
                    <InputForm
                      placeholder="Enter Website"
                      label="Website"
                      type="text"
                      id="website"
                      name="website"
                      value={website}
                      onChange={(event) => setWebsite(event.target.value)}
                      error={errors.website}
                    />
                  </div>
                </div>

                <div>
                  <div className="add-event__form-block-group">
                    <CustomDatePicker
                      label="Date start"
                      selectedDate={dateStart}
                      onChange={handleDateStartChange}
                      error={errors.dateStart}
                    />
                  </div>

                  <div className="add-event__form-block-group">
                    <CustomDatePicker
                      label="Date End"
                      selectedDate={dateEnd}
                      onChange={handleDateEndChange}
                      error={errors.dateEnd}
                    />
                  </div>
                </div>

                <div>
                  {promoterImg.map((img) => {
                    return (
                      <Image
                        key={img.id}
                        onAdd={handleImageAdd}
                        onRemove={() => handleImageRemove(img.id)}
                      />
                    );
                  })}
                </div>

                <div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label style={{ color: "#fff", padding: "0.5vw 2vw" }}>
                      About
                      <span className="error-message"> {errors.about}</span>
                    </label>
                    <textarea
                      rows={4}
                      id="venueDescription"
                      name="about"
                      value={about}
                      onChange={(event) => setAbout(event.target.value)}
                      placeholder="Enter about"
                      className="venue-info__form-group-about-input"
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  className="add-user__form-button"
                  disabled={loading}
                  style={{ opacity: loading ? 0.5 : 1 }}
                >
                  <p className="add-user__form-button-text">Add Information</p>
                  <img src={checkMark} alt="a checkmark"></img>
                </button>
              </section>
            </form>
          </section>
        </article>
      </section>
    </main>
  );
};

export default AddAd;
