import { useEffect, useState } from 'react'
import api from '../../services/api'
import "./Mobile.scss"

const MobileApp = () => {

    const [listContractsMobileApp, setListContractsMobileApp] = useState()

    const fecthData = async () => {
        try {

            const response = await api.get("/contracts/app/terms-and-conditions")

            setListContractsMobileApp(response.data)

        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        fecthData()
    }, [])

    const handleUpdateContracts = async () => {
        try {
            const text = {
                text: listContractsMobileApp
            }
            await api.put("/contracts/app/terms-and-conditions", text)

        } catch (error) {
            console.log(error)
        }

    }

    return (
        <>
            <textarea
                className='contracts-textarea'
                value={listContractsMobileApp}
                onChange={(event) => setListContractsMobileApp(event.target.value)}
            />

            <button className='button-terms ' onClick={handleUpdateContracts}>
                Save
            </button>
        </>
    )
}

export default MobileApp